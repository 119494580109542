import { set, get, flow, uniq } from "lodash/fp";
import {
  LOAD_CAMPAIGNS_REQUEST,
  LOAD_CAMPAIGNS_SUCCESS,
  LOAD_CAMPAIGNS_FAILURE,
  SAVE_CAMPAIGN_REQUEST,
  SAVE_CAMPAIGN_SUCCESS,
  SAVE_CAMPAIGN_FAILURE,
  PUBLISH_CAMPAIGN_FAILURE,
  PUBLISH_CAMPAIGN_REQUEST,
  PUBLISH_CAMPAIGN_SUCCESS,
  UPLOAD_URLS_REQUEST,
  UPLOAD_URLS_SUCCESS,
  LOAD_CAMPAIGN_DATA_REQUEST,
  LOAD_CAMPAIGN_DATA_SUCCESS,
  LOAD_CAMPAIGN_DATA_FAILURE,
  LOAD_RAF_REQUEST,
  LOAD_RAF_SUCCESS,
  UNPUBLISH_CAMPAIGN_REQUEST,
  UNPUBLISH_CAMPAIGN_SUCCESS,
} from "./actions";

const convertItem = (row) => {
  const publishedValues = JSON.parse(row.published_values);

  if (publishedValues && new Date(publishedValues.date_start) > new Date()) {
    row.status = "Pending";
  } else if (
    publishedValues &&
    new Date(publishedValues.date_expire) > new Date()
  ) {
    row.status = "Live";
  } else if (
    publishedValues &&
    new Date(publishedValues.date_expire) < new Date()
  ) {
    row.status = "Expired";
  } else {
    row.status = "Draft";
  }

  const logs = row.logs.sort((a, b) => {
    if (a.date > b.date) {
      return -1;
    } else if (a.date < b.date) {
      return 1;
    }
    return 0;
  });

  row.last_published = logs.length ? logs[0].date : null;

  return row;
};

const convertItems = (rows) => {
  return rows.map(convertItem).sort((itemA, itemB) => {
    if (itemA.last_published > itemB.last_published) {
      return -1;
    } else if (itemB.last_published > itemA.last_published) {
      return 1;
    }

    return 0;
  });
};

export const campaigns = (
  state = {
    list: [],
    error: null,
    loading: false,
    campaignData: {},
    raf: {},
    updaters: [],
  },
  action
) => {
  switch (action.type) {
    case LOAD_CAMPAIGNS_REQUEST:
    case SAVE_CAMPAIGN_REQUEST:
    case PUBLISH_CAMPAIGN_REQUEST:
    case UPLOAD_URLS_REQUEST:
    case LOAD_CAMPAIGN_DATA_REQUEST:
    case LOAD_RAF_REQUEST:
    case UNPUBLISH_CAMPAIGN_REQUEST:
      return flow(set("loading", true), set("error", null))(state);
    case LOAD_CAMPAIGNS_SUCCESS:
      let finalList = convertItems(action.payload).map((item) => ({
        ...item,
        date_expire: new Date(item.date_expire),
        date_start: new Date(item.date_start),
      }));
      console.log("FINAL LIST", finalList);
      let added = [];
      let updaters = finalList
        .map((item) => {
          return item.last_edit_by;
        })
        .filter((item) => {
          return !!item;
        })
        .filter((item) => {
          if (!added.includes(item)) {
            added.push(item);
            return true;
          }
        });

      return flow(
        set("loading", false),
        set("error", null),
        set("list", finalList),
        set("updaters", updaters)
      )(state);
    case SAVE_CAMPAIGN_SUCCESS:
      if (action.id) {
        return flow(
          set("loading", false),
          set("error", null),
          set(
            "list",
            state.list.map((item) => {
              if (Number(item.id) === Number(action.id)) {
                return {
                  ...item,
                  ...action.payload,
                  date_expire: new Date(action.payload.date_expire),
                  date_start: new Date(action.payload.date_start),
                };
              }
              return item;
            })
          )
        )(state);
      }
      return flow(
        set("loading", false),
        set("error", null),
        set("list", [
          ...state.list,
          {
            ...action.payload,
            date_expire: new Date(action.payload.date_expire),
            date_start: new Date(action.payload.date_start),
          },
        ])
      )(state);
    case LOAD_CAMPAIGNS_FAILURE:
    case SAVE_CAMPAIGN_FAILURE:
    case PUBLISH_CAMPAIGN_FAILURE:
    case LOAD_CAMPAIGN_DATA_FAILURE:
      return flow(set("loading", false), set("error", action.payload))(state);
    case UPLOAD_URLS_SUCCESS:
      return set("loading", false, state);
    case LOAD_CAMPAIGN_DATA_SUCCESS:
      return flow(
        set("loading", false),
        set("campaignData", action.payload)
      )(state);
    case LOAD_RAF_SUCCESS:
      return flow(set("loading", false), set("raf", action.payload))(state);
    default:
      return state;
  }
};
