import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { confirmAlert } from "react-confirm-alert";
import { useSelector, useDispatch } from "react-redux";
import AdditionalQuestions from "./assessment/AdditionalQuestions";

import {
  mainColor,
  errorWeak,
  errorStrong,
  warnBG,
  warnCopy,
} from "../styles/colors";
import { device } from "./Devices";
import {
  getLoading,
  getMaster,
  getAnswers,
  getIsOverrideDetected,
  getValidations,
  questionDeltas,
  getNotes,
} from "../store/assessment/selectors";
import {
  loadAssessmentMaster,
  resetAnswers,
  setValidations,
  restoreAssessmentAnswers,
  getAssessmentByUserAssessment,
} from "../store/assessment/actions";
import {
  getQuestionsWithComments,
  getShowQuestionsWithComments,
} from "../store/review/selectors";
import {
  loadCommentsForReview,
  setShowQuestionsWithComments,
} from "../store/review/actions";
import { getUser } from "../store/users/selectors";
import Button from "../components/Button";
import Progress from "../components/Progress";
import Loader from "../components/Loader";
import ResultScreen from "../components/ResultScreen";

import { getCreditContactDetails } from "../store/users/selectors";

import Questions from "./assessment/Questions";

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;

  position: relative;

  @media ${device.laptop} {
    padding: 30px 20px;
    ${(props) => !props.wrapped && "margin: 0px 30px 0px 0px;"}
  }

  &.no-pad {
    padding: 0px 20px;
  }
`;

const DisabledLayer = styled.div`
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  > div {
    text-align: center;
    padding: 30px;
  }

  justify-content: center;
  align-items: center;
  display: none;

  &.shown {
    display: flex;
  }

  & > div {
    font-size: 20px;
    font-weight: bold;
  }
  p {
    ${(props) => (props.disclaimer ? "text-align: left;" : "")}
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  margin-top: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
`;

const Errors = styled.div`
  padding: 15px;
  border: 1px solid ${errorStrong};
  color: ${errorStrong};
  background-color: ${errorWeak};
`;

const StyledProgress = styled(Progress)`
  margin-bottom: 20px;
`;

const FilterOptions = styled.div`
  background-color: #eee;
  padding: 10px;
`;

const warning = `background-color: ${warnBG};
border: 1px solid ${warnCopy};
color: ${warnCopy};
font-size: 14px;
padding: 10px;
margin-bottom: 20px;`;

const Caution = styled.div`
  ${warning}
`;

function Assessment({
  external = true,
  disabled,
  assessmentResult,
  onSubmit,
  contractorId,
  exclude,
  removeHeadings = false,
  target = "client",
  loadAssessment,
  wrapped = false,
  noCredits = false,
  onStepChange = () => {},
  dictateSteps = false,
  currentStep = 0,
  inReview = false,
  isReviewer = false,
  review = {},
  engagement = null,
  roleAssessmentId = null,
  dissemination = false,
  showDisclaimer = false,
  isManualReviewer = false,
  onOverride = false,
  additionalConditional = {},
  assessmentType = "FULL",
}) {
  const dispatch = useDispatch();
  const contactDetails = useSelector(getCreditContactDetails);
  const loading = useSelector(getLoading);
  let masterAssessment = useSelector(getMaster);

  const currentAnswers = useSelector(getAnswers);

  const hasOverride = useSelector(getIsOverrideDetected);
  const user = useSelector(getUser);

  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [reopen, setReopen] = useState(false);
  const [view, setView] = useState(false);

  const questionsWithComments = useSelector(getQuestionsWithComments);

  const hasQuestionsWithComments = Object.keys(questionsWithComments).length;

  const showQuestionsWithComments = useSelector(getShowQuestionsWithComments);

  const validations = useSelector(getValidations);

  const notes = useSelector(getNotes);

  const [displayDisclaimer, setDisplayDisclaimer] = useState(true);

  const [additional, setAdditional] = useState(null);

  const additionalAnswers = useSelector((state) => {
    return additional ? additional.getValues(state) : () => null;
  });

  const filterQuestions = (questions) => {
    if (!exclude && !showQuestionsWithComments) {
      return questions;
    }

    return questions.filter((question) => {
      let result1 = true;
      let result2 = true;

      if (exclude) {
        result1 = question.respondent !== exclude;
      }
      if (showQuestionsWithComments) {
        result2 = questionsWithComments.includes(question.id.toString());
      }

      return result1 && result2;
    });
  };

  const removeCategoriesWithNoQuestions = (categories) => {
    return categories.filter((category) => {
      return filterQuestions(category.questions).length > 0;
    });
  };

  let categories = removeCategoriesWithNoQuestions(
    masterAssessment ? masterAssessment.categories : []
  );

  if (masterAssessment) {
    categories = categories.sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      }
      if (b.order > a.order) {
        return -1;
      }
      return 0;
    });
  }

  useEffect(() => {
    if (review && review.id && assessmentResult && !reopen) {
      dispatch(loadCommentsForReview(review.id));
    }
  }, [review]);

  useEffect(() => {
    if (dictateSteps) {
      setCurrentIndex(currentStep);
    }
  }, [currentStep]);

  useEffect(() => {
    if (loadAssessment) {
      loadAssessment();
    } else if (!external && assessmentType) {
      reset();
      dispatch(loadAssessmentMaster(assessmentType));
    }
  }, [assessmentType]);

  useEffect(() => {
    if (masterAssessment) {
      setCurrentCategory(categories[currentIndex]);
    }
  }, [masterAssessment]);

  useEffect(() => {
    if (inReview && assessmentResult) {
      dispatch(getAssessmentByUserAssessment(assessmentResult.id));
      dispatch(restoreAssessmentAnswers(assessmentResult.id));
    }
  }, [inReview]);

  useEffect(() => {
    if ((reopen || view) && assessmentResult) {
      dispatch(getAssessmentByUserAssessment(assessmentResult.id));
      dispatch(restoreAssessmentAnswers(assessmentResult.id));
    }
  }, [reopen, view]);

  useEffect(() => {
    if (roleAssessmentId) {
      dispatch(getAssessmentByUserAssessment(roleAssessmentId));
      dispatch(restoreAssessmentAnswers(roleAssessmentId));
    }
  }, [roleAssessmentId]);

  useEffect(() => {
    if (masterAssessment) {
      setCurrentCategory(categories[currentIndex]);
      if (!inReview || !assessmentResult) {
        window.scroll(0, 0);
      }
    }
  }, [currentIndex, showQuestionsWithComments]);

  useEffect(() => {
    validatePage(true);
  }, [currentAnswers]);

  useEffect(() => {
    let config = { contractor: {}, client: {} };

    console.log("additionalConditional", additionalConditional);

    Object.keys(additionalConditional).forEach((key) => {
      if (additionalConditional[key]) {
        config.contractor[key] = "YES";
      }
    });

    setAdditional(new AdditionalQuestions(config, target));
  }, [target, additionalConditional]);

  const validateQuestion = (question) => {
    let hasAnswer = false;
    let invalidQuestions = [];
    question.answers.forEach((answer) => {
      if (currentAnswers.indexOf(answer.id) > -1) {
        hasAnswer = true;
        if (answer.questions.length) {
          answer.questions.forEach((child) => {
            invalidQuestions = invalidQuestions.concat(validateQuestion(child));
          });
        }
      }
    });

    if (!hasAnswer && question.respondent !== exclude) {
      invalidQuestions.push(question.id);
    }
    return invalidQuestions;
  };

  const reset = () => {
    setCurrentIndex(0);
    dispatch(resetAnswers());
  };

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const validatePage = (removeOnly) => {
    if (!currentCategory) {
      return [];
    }

    let invalid = [];
    currentCategory.questions.forEach((question) => {
      let ids = validateQuestion(question);

      invalid = invalid.concat(ids);
    });

    if (removeOnly) {
      const currentValidations = validations;
      invalid = invalid.filter((item) => currentValidations.indexOf(item) > -1);
    }

    dispatch(setValidations(invalid));

    return invalid;
  };

  const moveForward = async () => {
    //Validate first
    let validationErrors = validatePage();

    validationErrors = validationErrors.concat(additional.validate());

    if (validationErrors.length === 0) {
      if (currentIndex + 1 < categories.length && !hasOverride) {
        if (!dictateSteps) {
          setCurrentIndex(currentIndex + 1);
        }
        onStepChange(
          currentAnswers,
          currentIndex + 1,
          notes,
          additionalAnswers
        );
      } else {
        if (reopen) {
          const options = {
            title: "Are you sure?",
            message: [
              <p>This will cause the following:</p>,
              <li>The previous answers to be lost</li>,
            ],
            buttons: [
              {
                label: "Cancel",
              },
              {
                label: "Confirm",
                onClick: () => {
                  onSubmit(
                    masterAssessment.id,
                    currentAnswers,
                    true,
                    notes,
                    additionalAnswers
                  );
                  setReopen(false);
                },
              },
            ],
          };

          if (review && review.id) {
            options.message.push(<li>The previous reviews to be removed</li>);
          }

          if (
            engagement &&
            engagement.distributees &&
            engagement.distributees.length
          ) {
            options.message.push(<li>Any SDS acceptances to be cancelled</li>);
          }

          confirmAlert(options);
        } else {
          const assessmentId = await onSubmit(
            masterAssessment.id,
            currentAnswers,
            false,
            notes,
            additionalAnswers
          );
          if (assessmentId) {
            dispatch(restoreAssessmentAnswers(assessmentId));
          }
        }
      }
    }
  };

  const goBack = () => {
    if (!dictateSteps) {
      setCurrentIndex(currentIndex - 1);
    } else {
      onStepChange(currentAnswers, currentIndex - 1, notes);
    }
  };
  const isReviewerUse = view ? false : isReviewer;
  const isManualReviewerUse = view ? false : isManualReviewer;
  const noOverrideAndIsView = !hasOverride && view;
  const isLastPage = currentIndex === categories.length - 1;
  const inReviewAndIsReviewerAndNotManualReviewer =
    inReview && isReviewerUse && !isManualReviewerUse;

  const renderNextButton = () => {
    return (
      <Button action onClick={() => moveForward()}>
        {currentIndex === categories.length - 1 || hasOverride
          ? "Submit Answers"
          : "Next"}
      </Button>
    );
  };

  const generateButtons = () => {
    let nextButtonRender = null;

    // {(noOverrideAndIsView && !isLastPage) ||
    //   (isLastPage && !isManualReviewerUse && !isReviewerUse && !view) ||
    //   (isLastPage && !inReview && !view) ||
    //   (!isLastPage && !view && !(isReviewerUse && inReview)) ||
    //   (!isLastPage && inReviewAndIsReviewerAndNotManualReviewer && !view) ||
    //   (!view && inReview && isManualReviewerUse && !isLastPage) ? (

    //   ) : null}

    if (view && !isLastPage) {
      nextButtonRender = renderNextButton();
    } else if (view && isLastPage) {
      nextButtonRender = null;
    } else if (isLastPage) {
      if (inReview) {
        if (!isManualReviewerUse && !isReviewerUse) {
          nextButtonRender = renderNextButton(0);
        }
      } else {
        nextButtonRender = renderNextButton();
      }
    } else {
      nextButtonRender = renderNextButton();
    }

    return (
      <Buttons>
        {currentIndex > 0 ? (
          <Button action onClick={() => goBack()}>
            Previous
          </Button>
        ) : (
          <div />
        )}

        {nextButtonRender}
      </Buttons>
    );
  };

  const showEdit =
    !assessmentResult ||
    (inReview &&
      assessmentResult &&
      assessmentResult.status !== "AWAITING_CONTRACTOR") ||
    reopen ||
    view;

  return (
    <Wrapper className={assessmentResult ? "no-pad" : ""} wrapped={wrapped}>
      {disabled && !noCredits && (
        <DisabledLayer className="shown">
          <div>Save Engagement details to commence assessment</div>
        </DisabledLayer>
      )}
      {noCredits && (
        <DisabledLayer className="shown">
          <div>
            You currently have no credits available, please contact us to
            purchase more: {contactDetails}
          </div>
        </DisabledLayer>
      )}
      {displayDisclaimer && showDisclaimer && !disabled && !noCredits && (
        <DisabledLayer className="shown" disclaimer>
          <div>
            <p>
              <strong>Please Note</strong>: Your answers MUST reflect the
              reality of the contract &amp; working practices, and MUST be able
              to be supported by evidence. This status tool reflects the
              information entered into the system. If the information is wrong
              this may result in an INCORRECT DETERMINATION.
            </p>
            <p>
              To use this tool you must have an appropriate understanding of
              IR35. Guidance is provided to assist/remind you about various
              areas of IR35 but are not meant to be a substitute for knowledge
              of the law.
            </p>
            <p>
              If you do not have adequate knowledge of IR35 to use this tool
              then please seek expert help.
            </p>
            <Button
              noFullWidth={true}
              onClick={() => setDisplayDisclaimer(false)}
            >
              Continue
            </Button>
          </div>
        </DisabledLayer>
      )}
      {loading || !masterAssessment || !currentCategory ? (
        <Loader text={`Loading, please wait`} />
      ) : (
        <div>
          {showEdit ? (
            <div>
              {assessmentResult && (inReview || reopen || view) ? (
                <ResultScreen
                  assessmentType={assessmentType}
                  summaryOnly
                  assessmentResult={assessmentResult}
                  contractorId={contractorId}
                  inReview={inReview}
                  review={review}
                  reopen={reopen}
                  setReopen={setReopen}
                  view={view}
                  setView={setView}
                  isManualReviewer={isManualReviewerUse}
                  onOverride={onOverride}
                />
              ) : null}
              {!removeHeadings && (
                <>
                  {" "}
                  <h2>{currentCategory.title}</h2>
                  <h3>{currentCategory.advice}</h3>{" "}
                </>
              )}
              <StyledProgress
                step={currentIndex + 1}
                numberOfSteps={categories.length}
              />
              {assessmentResult && !reopen && !!hasQuestionsWithComments && (
                <FilterOptions>
                  <input
                    type="checkbox"
                    id="comments-only"
                    checked={showQuestionsWithComments}
                    onChange={(e) =>
                      dispatch(setShowQuestionsWithComments(e.target.checked))
                    }
                  />
                  <label for="comments-only">
                    Only show questions with comments
                  </label>
                </FilterOptions>
              )}
              Type: {assessmentType}
              {generateButtons()}
              {isReviewerUse && inReview && !isManualReviewerUse && (
                <Caution>
                  You are assigned as a reviewer to this assessment and
                  therefore are unable to make any changes.
                </Caution>
              )}
              <Questions
                isReviewer={inReview && (isReviewerUse || isManualReviewerUse)}
                questions={filterQuestions(currentCategory.questions)}
                target={target}
                reviewId={review && review.id}
                assessmentResult={!reopen && assessmentResult}
                dissemination={dissemination}
                view={view}
                additional={additional}
              />
              {validations.length ? (
                <Errors>Please correct the errors and continue</Errors>
              ) : null}
              {generateButtons()}
            </div>
          ) : (
            <ResultScreen
              assessmentType={assessmentType}
              assessmentResult={assessmentResult}
              contractorId={contractorId}
              review={review}
              engagement={engagement}
              reopen={reopen}
              setReopen={setReopen}
              view={view}
              setView={setView}
            />
          )}
        </div>
      )}
    </Wrapper>
  );
}

export default Assessment;
