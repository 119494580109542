import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { debounce } from "lodash/fp";
import { StyledDataTable, darkTheme } from "../components/TableStyles";
import { TitleArea, ContentArea, Empty, Wrapper } from "../components/Elements";
import noContractors from "../assets/images/no-contractors.svg";
import Button from "../components/Button";
import Loader from "../components/Loader";
import { mainColor } from "../styles/colors";
import SplitButton from "../components/SplitButton";
import {
  getManualReviews,
  getManualReviewsError,
  getManualReviewsLoading,
  getCurrentPage,
  getPerPage,
  getTotalRows,
  getFilterOpenClosed,
  getFilterAssigned,
  getAssignLoading,
  getSearchTerm,
} from "../store/review/selectors";
import {
  loadManualReviews,
  setPage,
  setPerPage,
  setFilterOpenClosed,
  setFilterAssigned,
  assignReview,
  unassignReview,
  setSearchTerm,
} from "../store/review/actions";
import { getUserId } from "../store/users/selectors";
import FormField from "../components/FormField";
const TableWrapper = styled.div`
  margin: 0px 20px;
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 4px;
  flex: 1;
`;

const Name = styled.div`
  .initials {
    border-radius: 50%;
    background-color: ${mainColor};
    color: #fff;
    text-align: center;
    line-height: 30px;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    display: inline-block;
  }
`;

const Filters = styled.div`
  display: inline-flex;
  margin-top: 20px;

  & > div {
    margin-right: 10px;
  }
`;

const SearchArea = styled.div`
  width: 465px;
  margin-top: 10px;
`;

function ManualReviews({ history, match }) {
  const dispatch = useDispatch();

  const loading = useSelector(getManualReviewsLoading);
  const data = useSelector(getManualReviews);

  const totalRows = useSelector(getTotalRows);
  const perPage = useSelector(getPerPage);
  const page = useSelector(getCurrentPage) + 1;

  const filterAssigned = useSelector(getFilterAssigned);
  const filterOpenClosed = useSelector(getFilterOpenClosed);

  const assignLoading = useSelector(getAssignLoading);
  const userId = useSelector(getUserId);

  const searchTerm = useSelector(getSearchTerm);

  const onFilterAssignedChange = (newValue) =>
    dispatch(setFilterAssigned(newValue));

  const onFilterOpenClosedChange = (newValue) =>
    dispatch(setFilterOpenClosed(newValue));

  const loadDebounce = React.useCallback(
    (page, perPage, filterOpenClosed, filterAssigned, searchTerm) => {
      console.log("Search term", searchTerm);
      dispatch(setPage(0));
      dispatch(
        loadManualReviews(
          page,
          perPage,
          filterOpenClosed,
          filterAssigned,
          searchTerm
        )
      );
    }
  );

  const debouncedLoad = useRef(debounce(400, loadDebounce)).current;

  useEffect(() => {
    debouncedLoad(
      1,
      perPage,
      filterAssigned ? filterOpenClosed : "OPEN",
      filterAssigned,
      searchTerm
    );
  }, [searchTerm]);

  // useEffect(() => {
  //   debouncedLoad(page, perPage, filterOpenClosed, filterAssigned, searchTerm);
  // }, []);

  useEffect(() => {
    debouncedLoad(
      1,
      perPage,
      filterAssigned ? filterOpenClosed : "OPEN",
      filterAssigned,
      searchTerm
    );

    dispatch(setPage(0));
    if (!filterAssigned) {
      dispatch(setFilterOpenClosed("OPEN"));
    }
  }, [filterAssigned]);

  useEffect(() => {
    debouncedLoad(
      1,
      perPage,
      filterOpenClosed,
      filterOpenClosed === "CLOSED" ? true : filterAssigned,
      searchTerm
    );

    dispatch(setPage(0));
    if (filterOpenClosed === "CLOSED") {
      dispatch(setFilterAssigned(true));
    }
  }, [filterOpenClosed]);

  const movePage = async (page) => {
    dispatch(
      loadManualReviews(
        page,
        perPage,
        filterOpenClosed,
        filterAssigned,
        searchTerm
      )
    );
    dispatch(setPage(page - 1));
  };

  const changePerPage = async (newPerPage, page) => {
    dispatch(
      loadManualReviews(
        page,
        newPerPage,
        filterOpenClosed,
        filterAssigned,
        searchTerm
      )
    );
    dispatch(setPerPage(newPerPage));
    dispatch(setPage(page - 1));
  };

  const NameField = ({ row, onClick }) => {
    var parts = row.contractor_name.split(" ");
    return (
      <Name onClick={onClick}>
        <span className="initials">
          {parts[0] && parts[0][0]}
          {parts[1] && parts[1][0]}
        </span>
        {row.contractor_name}
      </Name>
    );
  };

  const renderTable = () => {
    const columns = [
      {
        name: "Review Opened",
        width: 120,
        cell: (row) => {
          if (row.review_generated) {
            return moment(row.review_generated).format("DD/MM/YYYY");
          }
          return "Not Available";
        },
      },
      {
        name: "Contractor",
        cell: (row) => (
          <NameField
            row={row}
            onClick={() => {
              handleRowClicked(row);
            }}
          />
        ),
        width: 180,
      },
      {
        name: "Partner",
        selector: "company_name",
        sortable: true,
        width: 200,
      },

      {
        name: "Result",
        sortable: true,
        selector: "result",
      },
      {
        cell: (row) => {
          return (
            <>
              {(!row.manual_reviewer ||
                Number(row.manual_reviewer) === userId) && (
                <Button
                  action
                  type="review"
                  size="reduced"
                  loading={assignLoading === row.id}
                  onClick={() => {
                    !row.manual_reviewer
                      ? dispatch(assignReview(row.approval_id, userId))
                      : dispatch(unassignReview(row.approval_id));
                  }}
                >
                  {row.manual_reviewer ? "Unassign" : "Assign"}
                </Button>
              )}
            </>
          );
        },
        button: true,
      },
    ];

    //main/manual-reviews/assessment/:companyId/:contractorId/:mode
    const handleRowClicked = (row) => {
      let url;

      if (row.contractor_id !== -1) {
        url = `manual-reviews/assessment/${row.company_id}/${row.contractor_id}/${row.engagement_id}`;
      } else {
        url = `manual-reviews/role/${row.company_id}/rolebased/${row.engagement_id}`;
      }

      history.push(url);
    };
    return (
      <TableWrapper>
        <StyledDataTable
          progressPending={loading}
          pagination
          paginationServer
          customTheme={darkTheme}
          columns={columns}
          data={data}
          fixedHeader
          highlightOnHover
          fixedHeaderScrollHeight="100%"
          title="All Reviews"
          onRowClicked={handleRowClicked}
          paginationDefaultPage={page}
          paginationPerPage={perPage}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={changePerPage}
          onChangePage={movePage}
        />
      </TableWrapper>
    );
  };

  return (
    <Wrapper>
      <TitleArea>
        <div className="title">
          <h1>Manual Reviews</h1>
          <Filters>
            <SplitButton
              value={filterAssigned}
              onChange={onFilterAssignedChange}
              options={[
                { value: false, display: "Unassigned" },
                { value: true, display: "Assigned" },
              ]}
            />
            <SplitButton
              value={filterOpenClosed}
              onChange={onFilterOpenClosedChange}
              options={[
                { value: "OPEN", display: "Open/Incomplete" },
                { value: "CLOSED", display: "Closed/Complete" },
              ]}
            />
          </Filters>
          <SearchArea>
            <FormField
              placeholder="Search"
              type="text"
              changeMonitor={(value) => dispatch(setSearchTerm(value))}
              className="search"
              value={searchTerm}
            />
          </SearchArea>
        </div>
        <div className="controls"></div>
      </TitleArea>
      <ContentArea>
        {loading ? (
          <Loader />
        ) : data.length ? (
          renderTable()
        ) : (
          <div>
            <Empty>
              <img src={noContractors} />
              <h2>No Reviews</h2>
              <p>There are no manual reviews to carry out currently</p>
            </Empty>
          </div>
        )}
      </ContentArea>
    </Wrapper>
  );
}

export default ManualReviews;
