import { fetch } from "../fetchMiddleware";

export const CREATE_CONTRACTOR_REQUEST = "CREATE_CONTRACTOR_REQUEST";
export const CREATE_CONTRACTOR_SUCCESS = "CREATE_CONTRACTOR_SUCCESS";
export const CREATE_CONTRACTOR_FAILURE = "CREATE_CONTRACTOR_ERROR";

export const createContractor = (data) => async (dispatch) => {
  dispatch({ type: CREATE_CONTRACTOR_REQUEST });

  try {
    const { data: response } = await dispatch(
      fetch(`/Contractors`, {
        method: "POST",
        data,
      })
    );
    await dispatch({
      type: CREATE_CONTRACTOR_SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: CREATE_CONTRACTOR_FAILURE,
      payload: error & error.response && error.response.data,
    });
  }
};

export const LOAD_CONTRACTOR_REQUEST = "LOAD_CONTRACTOR_REQUEST";
export const LOAD_CONTRACTOR_SUCCESS = "LOAD_CONTRACTOR_SUCCESS";
export const LOAD_CONTRACTOR_FAILURE = "LOAD_CONTRACTOR_FAILURE";

export const loadContractor = (id, companyId, engagementId) => async (
  dispatch
) => {
  dispatch({ type: LOAD_CONTRACTOR_REQUEST });

  let url =
    id === "rolebased"
      ? `/Contractors/rolebased/${companyId}`
      : `/Contractors/${id}`;

  url += "?filter[include]=engagements";

  if (engagementId && engagementId !== "new" && id !== "rolebased") {
    url = `/Contractors/${id}/include-engagement/${engagementId}`;
  }

  try {
    const { data: response } = await dispatch(
      fetch(url, {
        method: "GET",
      })
    );
    await dispatch({
      type: LOAD_CONTRACTOR_SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: LOAD_CONTRACTOR_FAILURE,
      payload: error,
    });
  }
};

export const LOAD_CONTRACTORS_REQUEST = "LOAD_CONTRACTORS_REQUEST";
export const LOAD_CONTRACTORS_SUCCESS = "LOAD_CONTRACTORS_SUCCESS";
export const LOAD_CONTRACTORS_FAILURE = "LOAD_CONTRACTORS_FAILURE";

export const loadContractors = (
  search = "",
  companyId,
  standalone,
  page,
  perPage
) => async (dispatch) => {
  dispatch({ type: LOAD_CONTRACTORS_REQUEST });

  if (!standalone) {
    standalone = false;
  }

  let url = "/Contractors";
  const searchFields = ["first_name", "last_name", "email"];

  page = page - 1;

  const paginationFilter = `,"skip":${page * perPage}, "limit": ${perPage}`;

  if (search) {
    let searchItems = searchFields.map(
      (field) => `{"${field}":{"like":"%${search}%","options":"i"}}`
    );
    url += `?filter=${encodeURIComponent(
      `{"company": ${companyId}, "where": { "and": [{"or": [${searchItems.join(
        ","
      )}]}, {"standalone": ${standalone}}]}${paginationFilter}}`
    )}`;
  } else {
    url += `?filter=${encodeURIComponent(
      `{"company": ${companyId}, "where": {"standalone": ${standalone}}${paginationFilter}}`
    )}`;
  }

  try {
    const result = await dispatch(
      fetch(url, {
        method: "GET",
      })
    );

    const { data: response } = result;
    const count = result.headers["x-total-count"];
    await dispatch({
      type: LOAD_CONTRACTORS_SUCCESS,
      payload: { response, count },
    });
    return response;
  } catch (error) {
    await dispatch({
      type: LOAD_CONTRACTORS_FAILURE,
      payload: error,
    });
  }
};

export const SAVE_CONTRACTOR_REQUEST = "SAVE_CONTRACTOR_REQUEST";
export const SAVE_CONTRACTOR_SUCCESS = "SAVE_CONTRACTOR_SUCCESS";
export const SAVE_CONTRACTOR_FAILURE = "SAVE_CONTRACTOR_FAILURE";

export const saveContractor = (data) => async (dispatch) => {
  dispatch({ type: SAVE_CONTRACTOR_REQUEST });

  try {
    const { data: response } = await dispatch(
      fetch(`/Contractors/${data.id}`, {
        method: "PATCH",
        data,
      })
    );
    await dispatch({
      type: SAVE_CONTRACTOR_SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: SAVE_CONTRACTOR_FAILURE,
      payload: error & error.response && error.response.data,
    });
  }
};

export const REMOVE_CONTRACTOR_REQUEST = "REMOVE_CONTRACTOR_REQUEST";
export const REMOVE_CONTRACTOR_SUCCESS = "REMOVE_CONTRACTOR_SUCCESS";
export const REMOVE_CONTRACTOR_FAILURE = "REMOVE_CONTRACTOR_FAILURE";

export const removeContractor = (id) => async (dispatch) => {
  dispatch({ type: REMOVE_CONTRACTOR_REQUEST });

  try {
    const { data: response } = await dispatch(
      fetch(`/Contractors/hide/${id}`, {
        method: "GET",
      })
    );
    await dispatch({
      type: REMOVE_CONTRACTOR_SUCCESS,
      payload: {
        id,
      },
    });
    return response;
  } catch (error) {
    await dispatch({
      type: REMOVE_CONTRACTOR_FAILURE,
      payload: error && error.response && error.response.data,
    });
  }
};

export const STANDALONE_SUMMARY_REQUEST = "STANDALONE_SUMMARY_REQUEST";
export const STANDALONE_SUMMARY_SUCCESS = "STANDALONE_SUMMARY_SUCCESS";
export const STANDALONE_SUMMARY_FAILURE = "STANDALONE_SUMMARY_FAILURE";

export const requestStandaloneSummary = () => async (dispatch) => {
  dispatch({
    type: STANDALONE_SUMMARY_REQUEST,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/Contractors/standalone-summary`, {
        method: "GET",
      })
    );

    dispatch({
      type: STANDALONE_SUMMARY_SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: STANDALONE_SUMMARY_FAILURE,
      payload: error,
    });
  }
};

export const SET_STANDALONE_PAGE = "SET_STANDALONE_PAGE";

export const setPageStandalone = (page) => (dispatch) => {
  dispatch({
    type: SET_STANDALONE_PAGE,
    payload: page,
  });
};

export const SET_PAGE = "SET_PAGE";

export const setPage = (page) => (dispatch) => {
  dispatch({
    type: SET_PAGE,
    payload: page,
  });
};

export const SET_PER_PAGE = "SET_PER_PAGE";

export const setPerPage = (perPage) => (dispatch) => {
  dispatch({
    type: SET_PER_PAGE,
    payload: perPage,
  });
};

export const SET_STANDALONE_PER_PAGE = "SET_STANDALONE_PER_PAGE";

export const setPerPageStandalone = (perPage) => (dispatch) => {
  dispatch({
    type: SET_STANDALONE_PER_PAGE,
    payload: perPage,
  });
};

export const SET_SEARCH_TERM = "SET_SEARCH_TERM";

export const setSearchTerm = (term) => (dispatch) => {
  dispatch({
    type: SET_SEARCH_TERM,
    payload: term,
  });
};

export const SET_STANDALONE_SEARCH_TERM = "SET_STANDALONE_SEARCH_TERM";

export const setSearchTermStandalone = (term) => (dispatch) => {
  dispatch({
    type: SET_STANDALONE_SEARCH_TERM,
    payload: term,
  });
};

export const CHECK_SHARE_LEADS_REQUEST = "CHECK_SHARE_LEADS_REQUEST";
export const CHECK_SHARE_LEADS_SUCCESS = "CHECK_SHARE_LEADS_SUCCESS";
export const CHECK_SHARE_LEADS_FAILURE = "CHECK_SHARE_LEADS_FAILURE";

export const checkCanShareLeads = (companyId) => async (dispatch) => {
  dispatch({
    type: CHECK_SHARE_LEADS_REQUEST,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/Companies/should-share-leads/${companyId}`, {
        method: "GET",
      })
    );

    dispatch({
      type: CHECK_SHARE_LEADS_SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: CHECK_SHARE_LEADS_FAILURE,
      payload: error,
    });
  }
};
