import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from "react-redux";
import Bootstrap from "./components/Bootstrap";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./pages/Login";
import ErrorBoundary from "./components/ErrorBoundary";
import Forgot from "./pages/Forgot";
import ContractorPartAssessment from "./pages/ContractorPartAssessment";
import ContractorMainAssessment from "./pages/ContractorMainAssessment";
import AcceptSDS from "./pages/AcceptSDS";
import ContractorMainAssessmentResult from "./pages/ContractorMainAssessmentResult";
import ContractorMainAssessmentList from "./pages/ContractorMainAssessmentList";
import RAFRedirect from "./pages/RAFRedirect";
import CampaignRedirect from "./pages/CampaignRedirect";
import ReferAFriend from "./pages/ReferAFriend";
import ChangePassword from "./pages/ChangePassword";
import LoggedIn from "./layouts/LoggedIn";
import store from "./store";
import { StripeProvider, Elements } from "react-stripe-elements";
import { createGlobalStyle } from "styled-components";
import Blinker from "./assets/fonts/Blinker-Regular.ttf";
const GlobalStyles = createGlobalStyle`
  body {
    @font-face {
      font-family: 'Blinker', sans-serif;
      src: url(${Blinker});
    }
    font-family: Blinker;
    
    background-color: #f6f6f6;
  }`;

function App() {
  if (window.location.search) {
    window.localStorage.setItem("orgid", window.location.search.split("=")[1]);
  }

  var head = document.head || document.getElementsByTagName("head")[0];

  function changeFavicon(src) {
    var link = document.createElement("link"),
      oldLink = document.getElementById("dynamic-favicon");
    link.id = "dynamic-favicon";
    link.rel = "shortcut icon";
    link.href = src;
    if (oldLink) {
      head.removeChild(oldLink);
    }
    head.appendChild(link);
  }

  let subdomain = window.location.host.split(".")[0];

  if (subdomain === "crunch" || subdomain === "localhost:3002") {
    changeFavicon("https://www.crunch.co.uk/favicon.ico");
  }

  const params = new URLSearchParams(window.location.search);
  if (params.has("partner")) {
    sessionStorage.setItem("passedReference", params.get("partner"));
  }
  if (params.has("campaign")) {
    sessionStorage.setItem("campaign", params.get("campaign"));
  }
  if (params.has("RAF")) {
    sessionStorage.setItem("RAF", params.get("RAF"));
  }

  return (
    <Provider store={store}>
      <div className="App">
        <ErrorBoundary>
          <StripeProvider
            apiKey={
              subdomain === "crunch"
                ? process.env.REACT_APP_STRIPE_KEY_CRUNCH
                : process.env.REACT_APP_STRIPE_KEY_KB
            }
          >
            <Elements>
              <Bootstrap>
                <GlobalStyles />
                <Router>
                  <Route path="/forgot-password" exact component={Forgot} />
                  <Route
                    path="/change-password/:token"
                    exact
                    component={ChangePassword}
                  />
                  <ProtectedRoute path="/main" component={LoggedIn} />
                  <Route path="/" exact component={Login} />
                  <Route
                    path="/contractor-part-assessment/:assessmenthash"
                    exact
                    component={ContractorPartAssessment}
                  />
                  <Route
                    path="/contractor-assessment/:step"
                    component={ContractorMainAssessment}
                    exact
                  />
                  <Route
                    path="/refer-a-friend"
                    component={ReferAFriend}
                    exact
                  />
                  <Route path="/RAF/:raf_code" component={RAFRedirect} exact />
                  <Route
                    path="/campaign/:campaign_code"
                    component={CampaignRedirect}
                    exact
                  />
                  <Route
                    path="/contractor-assessment/plus/:crunch_salesforce_id/:step"
                    component={ContractorMainAssessment}
                    exact
                  />
                  <Route
                    path="/contractor-assessment/result/:token"
                    component={ContractorMainAssessmentResult}
                    exact
                  />
                  <Route
                    path="/contractor-assessment-list"
                    component={ContractorMainAssessmentList}
                    exact
                  />
                  <Route path="/sign-sds/:token" component={AcceptSDS} exact />
                </Router>
              </Bootstrap>
            </Elements>
          </StripeProvider>
        </ErrorBoundary>
      </div>
    </Provider>
  );
}

export default App;
