import { set, flow, get, getOr } from "lodash/fp";
import {
  LOAD_ASSESSMENT_MASTER_REQUEST,
  LOAD_ASSESSMENT_MASTER_SUCCESS,
  RESET_ANSWERS,
  ADD_ANSWER,
  REMOVE_ANSWER,
  LOAD_CLIENTS_SUPPLIERS_REQUEST,
  LOAD_CLIENTS_SUPPLIERS_SUCCESS,
  ADD_NEW_CLIENT,
  ADD_NEW_SUPPLIER,
  SUBMIT_ASSESSMENT_REQUEST,
  SUBMIT_ASSESSMENT_SUCCESS,
  CLEAR_REPORTING_DATA,
  LOAD_REPORTING_DATA_SUCCESS,
  LOAD_DASHBOARD_SUCCESS,
  SET_VALIDATIONS,
  SUBMIT_CONTRACTOR_ANSWERS_REQUEST,
  SUBMIT_CONTRACTOR_ANSWERS_SUCCESS,
  SUBMIT_CONTRACTOR_ANSWERS_FAILURE,
  CONTRACTOR_GET_ASSESSMENT_MASTER_BY_TOKEN_REQUEST,
  CONTRACTOR_GET_ASSESSMENT_MASTER_BY_TOKEN_FAILURE,
  CONTRACTOR_GET_ASSESSMENT_MASTER_BY_TOKEN_SUCCESS,
  CONTRACTOR_GENERATE_ASSESSMENT_REQUEST,
  CONTRACTOR_GENERATE_ASSESSMENT_SUCCESS,
  CONTRACTOR_GENERATE_ASSESSMENT_FAILURE,
  CONTRACTOR_SUBMIT_QUESTIONS_REQUEST,
  CONTRACTOR_SUBMIT_QUESTIONS_SUCCESS,
  CONTRACTOR_SUBMIT_QUESTIONS_FAILURE,
  CONTRACTOR_RESTORE_ANSWERS_SUCCESS,
  CONTRACTOR_RESTORE_ANSWERS_REQUEST,
  CONTRACTOR_RESTORE_ANSWERS_FAILURE,
  CONTRACTOR_CLOSE_ASSESSMENT_REQUEST,
  CONTRACTOR_CLOSE_ASSESSMENT_FAILURE,
  CONTRACTOR_LOAD_RESULT_REQUEST,
  CONTRACTOR_LOAD_RESULT_SUCCESS,
  CONTRACTOR_LOAD_RESULT_FAILURE,
  CONTRACTOR_BUY_REPORT_REQUEST,
  CONTRACTOR_BUY_REPORT_SUCCESS,
  CONTRACTOR_BUY_REPORT_FAILURE,
  SEND_LEAD_REQUEST,
  SEND_LEAD_SUCCESS,
  SEND_LEAD_FAILURE,
  CLEAR_LEAD_FLAGS,
  GET_ASSESSMENT_BY_USER_ASSESSMENT_SUCCESS,
  GET_ASSESSMENT_BY_USER_ASSESSMENT_REQUEST,
  GET_ASSESSMENT_BY_USER_ASSESSMENT_FAILURE,
  RESTORE_ASSESSMENT_ANSWERS_REQUEST,
  RESTORE_ASSESSMENT_ANSWERS_SUCCESS,
  RESTORE_ASSESSMENT_ANSWERS_FAILURE,
  SAVE_ENGAGEMENT_REQUEST,
  SAVE_ENGAGEMENT_SUCCESS,
  SAVE_ENGAGEMENT_FAILURE,
  LOAD_ROLE_ASSESSMENTS_REQUEST,
  LOAD_ROLE_ASSESSMENTS_SUCCESS,
  LOAD_ROLE_ASSESSMENTS_FAILURE,
  REMOVE_ROLE_ASSESSMENTS_REQUEST,
  REMOVE_ROLE_ASSESSMENTS_SUCCESS,
  REMOVE_ROLE_ASSESSMENTS_FAILURE,
  LOAD_CONTRACTOR_ASSESSMENTS_REQUEST,
  LOAD_CONTRACTOR_ASSESSMENTS_SUCCESS,
  LOAD_CONTRACTOR_ASSESSMENTS_FAILURE,
  SUBMIT_OVERRIDE_REQUEST,
  SUBMIT_OVERRIDE_SUCCESS,
  ADD_NEW_NOTE,
  LOAD_DASHBOARD_REQUEST,
  CHANGE_ARCHIVE_REQUEST,
  CHANGE_ARCHIVE_SUCCESS,
  CHANGE_ARCHIVE_SETTING,
  SET_PROGRESS_SEARCH_TERM,
  SET_PROGRESS_FROM,
  SET_PROGRESS_TO,
  SET_PROGRESS_INSIDE,
  SET_PROGRESS_BORDERLINE,
  SET_PROGRESS_OUTSIDE,
  LOAD_NOTIFICATION_USERS_REQUEST,
  LOAD_NOTIFICATION_USERS_SUCCESS,
  SUBSCRIBE_REQUEST,
  SUBSCRIBE_SUCCESS,
  SET_ADDITIONAL_ANSWER,
} from "./actions";

export const assessment = (
  state = {
    master: null,
    loading: false,
    answerMappings: [],
    childrenMappings: [],
    answers: [],
    loadingClientsSuppliers: false,
    clients: [],
    suppliers: [],
    reportingData: null,
    dashboardData: null,
    dashboardLoading: false,
    overrides: [],
    validations: [],
    submissionError: false,
    assessmentToken: null,
    contractorResult: {},
    purchaseFailure: null,
    leadSuccess: false,
    leadError: false,
    leadLoading: false,
    purchaseLoading: false,
    roleAssessmentsLoading: false,
    roleAssessmentsError: false,
    roleAssessments: [],
    contractorAssessmentsLoading: false,
    contractorAssessmentsError: false,
    contractorAssessments: false,
    deltas: {},
    notes: {},
    archiveSetting: false,
    progressSearchTerm: "",
    progressFrom: null,
    progressTo: null,
    progressInside: true,
    progressBorderline: true,
    progressOutside: true,
    subscribers: [],
    subscribersLoading: false,
    additionalAnswers: {},
    shouldShareLeads: false,
  },
  action
) => {
  const answerId = action.payload ? action.payload.answerId : null;
  let currentAnswers = getOr([], "answers", state);
  let mappings = get("answerMappings", state);
  let mappingsChildren = get("childrenMappings", state);
  let mapping = mappings && mappings[answerId];
  let newAnswers = currentAnswers && [...currentAnswers];

  const removeChildren = (newAnswers) =>
    newAnswers.filter((newAnswer) => {
      var val = true;
      Object.keys(mappingsChildren).forEach((parent) => {
        if (Number(parent) !== 0) {
          let set = mappingsChildren[parent];
          if (set.includes(newAnswer) && !newAnswers.includes(Number(parent))) {
            val = false;
          }
        }
      });
      return val;
    });

  switch (action.type) {
    case LOAD_CONTRACTOR_ASSESSMENTS_REQUEST:
      return flow(
        set("contractorAssessmentsLoading", true),
        set("contractorAssessmentsError", false)
      )(state);
    case LOAD_CONTRACTOR_ASSESSMENTS_FAILURE:
      return flow(
        set("contractorAssessmentsLoading", false),
        set("contractorAssessmentsError", true)
      )(state);
    case LOAD_CONTRACTOR_ASSESSMENTS_SUCCESS:
      return flow(
        set("contractorAssessmentsLoading", false),
        set("contractorAssessmentsError", false),
        set("contractorAssessments", action.payload)
      )(state);
    case REMOVE_ROLE_ASSESSMENTS_REQUEST:
    case LOAD_ROLE_ASSESSMENTS_REQUEST:
      return flow(
        set("roleAssessmentsLoading", true),
        set("roleAssessmentsError", false)
      )(state);
    case REMOVE_ROLE_ASSESSMENTS_FAILURE:
    case LOAD_ROLE_ASSESSMENTS_FAILURE:
      return flow(
        set("roleAssessmentsLoading", false),
        set("roleAssessmentsError", true)
      )(state);
    case REMOVE_ROLE_ASSESSMENTS_SUCCESS:
      return flow(
        set("roleAssessmentsLoading", false),
        set("roleAssessmentsError", false),
        set(
          "roleAssessments",
          state.roleAssessments.filter(
            (roleAssessment) => roleAssessment.id !== action.engagementId
          )
        )
      )(state);
    case LOAD_ROLE_ASSESSMENTS_SUCCESS:
      return flow(
        set("roleAssessmentsLoading", false),
        set("roleAssessmentsError", false),
        set("roleAssessments", action.payload)
      )(state);

    case CLEAR_LEAD_FLAGS:
      return flow(
        set("leadSuccess", false),
        set("leadError", false),
        set("leadLoading", false)
      )(state);
    case SEND_LEAD_REQUEST:
      return set("leadLoading", true, state);
    case SEND_LEAD_FAILURE:
      return flow(
        set("leadSuccess", false),
        set("leadError", true),
        set("leadLoading", false)
      )(state);

    case SEND_LEAD_SUCCESS:
      return flow(
        set("leadSuccess", true),
        set("leadError", false),
        set("leadLoading", false)
      )(state);
    case CONTRACTOR_BUY_REPORT_FAILURE:
      return flow(
        set("purchaseLoading", false),
        set("purchaseFailure", action.payload.data.body)
      )(state);
    case CONTRACTOR_BUY_REPORT_SUCCESS:
      return flow(
        set("purchaseLoading", false),
        set("purchaseFailure", false)
      )(state);
    case CONTRACTOR_LOAD_RESULT_SUCCESS:
      return flow(
        set("loading", false),
        set("contractorResult", action.payload),
        set("submissionError", false)
      )(state);
    case CONTRACTOR_SUBMIT_QUESTIONS_FAILURE:
    case SUBMIT_CONTRACTOR_ANSWERS_FAILURE:
    case CONTRACTOR_GET_ASSESSMENT_MASTER_BY_TOKEN_FAILURE:
    case GET_ASSESSMENT_BY_USER_ASSESSMENT_FAILURE:
    case CONTRACTOR_GENERATE_ASSESSMENT_FAILURE:
    case CONTRACTOR_RESTORE_ANSWERS_FAILURE:
    case RESTORE_ASSESSMENT_ANSWERS_FAILURE:
    case CONTRACTOR_CLOSE_ASSESSMENT_FAILURE:
    case CONTRACTOR_LOAD_RESULT_FAILURE:
    case SAVE_ENGAGEMENT_FAILURE:
      return flow(
        set("loading", false),
        set("submissionError", action.payload || true)
      )(state);
    case SUBMIT_CONTRACTOR_ANSWERS_REQUEST:
      return flow(set("loading", true), set("submissionError", false))(state);
    case SET_VALIDATIONS:
      return set("validations", action.payload, state);
    case CLEAR_REPORTING_DATA:
      return set("reportingData", null, state);
    case LOAD_REPORTING_DATA_SUCCESS:
      return set("reportingData", action.payload, state);
    case LOAD_CLIENTS_SUPPLIERS_REQUEST:
      return set("loadingClientsSuppliers", true, state);
    case LOAD_ASSESSMENT_MASTER_REQUEST:
    case SUBMIT_ASSESSMENT_REQUEST:
    case CONTRACTOR_GET_ASSESSMENT_MASTER_BY_TOKEN_REQUEST:
    case GET_ASSESSMENT_BY_USER_ASSESSMENT_REQUEST:
    case CONTRACTOR_GENERATE_ASSESSMENT_REQUEST:
    case CONTRACTOR_SUBMIT_QUESTIONS_REQUEST:
    case CONTRACTOR_RESTORE_ANSWERS_REQUEST:
    case RESTORE_ASSESSMENT_ANSWERS_REQUEST:
    case CONTRACTOR_CLOSE_ASSESSMENT_REQUEST:
    case CONTRACTOR_LOAD_RESULT_REQUEST:
    case SAVE_ENGAGEMENT_REQUEST:
    case SUBMIT_OVERRIDE_REQUEST:
      return set("loading", true, state);
    case CONTRACTOR_BUY_REPORT_REQUEST:
      return set("purchaseLoading", true, state);
    case CONTRACTOR_GENERATE_ASSESSMENT_SUCCESS:
      return flow(
        set("loading", false),
        set("assessmentToken", action.payload),
        set("submissionError", false)
      )(state);

    case SUBMIT_ASSESSMENT_SUCCESS:
    case SUBMIT_CONTRACTOR_ANSWERS_SUCCESS:
    case CONTRACTOR_SUBMIT_QUESTIONS_SUCCESS:
    case SUBMIT_OVERRIDE_SUCCESS:
    case SAVE_ENGAGEMENT_SUCCESS:
      return flow(set("loading", false), set("submissionError", false))(state);
    case LOAD_ASSESSMENT_MASTER_SUCCESS:
    case CONTRACTOR_GET_ASSESSMENT_MASTER_BY_TOKEN_SUCCESS:
    case GET_ASSESSMENT_BY_USER_ASSESSMENT_SUCCESS:
      //we need to semi flatten the questionnaire
      const questionnaire = action.payload;
      const questions = questionnaire.categories
        .map((category) => category.questions)
        .flat();

      let answerMappings = {};
      let childrenMappings = {};

      let overrides = [];

      function walkQuestions(questions, parent) {
        questions.map((question) => {
          walkAnswers(question.answers, parent);
        });
      }

      function walkAnswers(answers, parent) {
        answers.forEach((answer) => {
          if (answer.override) {
            overrides.push(answer.id);
          }
          childrenMappings[parent] = (childrenMappings[parent] || []).concat(
            answers.map((answer) => answer.id)
          );
          answerMappings[answer.id] = answers
            .filter((sibling) => sibling.id !== answer.id)
            .map((filteredSibling) => filteredSibling.id);
          if (answer.questions) {
            walkQuestions(answer.questions, answer.id);
          }
        });
      }
      walkQuestions(questions, 0);

      return flow(
        set("master", questionnaire),
        set("answerMappings", answerMappings),
        set("childrenMappings", childrenMappings),
        set("overrides", overrides),
        set("loading", false),
        set("shouldShareLeads", action.shouldShareLeads)
      )(state);
    case RESET_ANSWERS:
      return flow(
        set("answers", []),
        set("notes", {}),
        set("additionalAnswers", {})
      )(state);
    case CONTRACTOR_RESTORE_ANSWERS_SUCCESS:
    case RESTORE_ASSESSMENT_ANSWERS_SUCCESS:
      return flow(
        set(
          "answers",
          action.payload.currentAnswers.map((answer) => answer.id)
        ),
        set("deltas", action.payload.deltas),

        set("notes", action.payload.notes || {}),
        set("loading", false),
        //This set is hardcoded for now as no other questions
        set("additionalAnswers", {
          "PART_AND_PARCEL_BUSINESS-INDEMNITY": action.payload.additional || {},
        })
      )(state);
    case ADD_ANSWER:
      if (mapping) {
        newAnswers = currentAnswers.filter(
          (currentAnswer) => !mapping.find((item) => item === currentAnswer)
        );

        newAnswers = removeChildren(newAnswers);
      }

      return set("answers", [...newAnswers, answerId], state);

    case REMOVE_ANSWER:
      newAnswers = currentAnswers.filter(
        (currentAnswer) => currentAnswer !== answerId
      );
      newAnswers = removeChildren(newAnswers);
      return set("answers", newAnswers, state);
    case LOAD_CLIENTS_SUPPLIERS_SUCCESS:
      return flow(
        set("suppliers", action.payload.suppliers),
        set("clients", action.payload.clients),
        set("loadingClientsSuppliers", false)
      )(state);
    case ADD_NEW_CLIENT:
      return set("clients", [...state.clients, action.payload], state);
    case ADD_NEW_SUPPLIER:
      return set("suppliers", [...state.suppliers, action.payload], state);
    case LOAD_DASHBOARD_REQUEST:
    case CHANGE_ARCHIVE_REQUEST:
      return set("dashboardLoading", true, state);
    case LOAD_DASHBOARD_SUCCESS:
      return flow(
        set("dashboardData", action.payload),
        set("dashboardLoading", false)
      )(state);
    case CHANGE_ARCHIVE_SUCCESS:
      return flow(
        set("dashboardLoading", false),
        set(
          "dashboardData.reports",
          state.dashboardData.reports
            .map((item) => {
              if (item.id === action.id) {
                return { ...item, archived: action.archived };
              }
              return item;
            })
            .filter((item) => {
              if (item.id === action.id) {
                if (!state.archiveSetting) {
                  if (action.archived) {
                    return false;
                  }
                }
              }
              return true;
            })
        )
      )(state);
    case ADD_NEW_NOTE:
      const currentNotes = get("notes", state);
      const notesForQuestion = getOr([], `${action.questionId}`, currentNotes);

      notesForQuestion.push({
        note: action.note,
        name: action.name,
        datetime: action.date,
      });

      return set(
        "notes",
        set(action.questionId, notesForQuestion, currentNotes),
        state
      );
    case CHANGE_ARCHIVE_SETTING:
      return set("archiveSetting", action.setting, state);
    case SET_PROGRESS_SEARCH_TERM:
      return set("progressSearchTerm", action.term, state);
    case SET_PROGRESS_FROM:
      return set("progressFrom", action.from, state);
    case SET_PROGRESS_TO:
      return set("progressTo", action.to, state);
    case SET_PROGRESS_INSIDE:
      return set("progressInside", action.setting, state);
    case SET_PROGRESS_BORDERLINE:
      return set("progressBorderline", action.setting, state);
    case SET_PROGRESS_OUTSIDE:
      return set("progressOutside", action.setting, state);
    case LOAD_NOTIFICATION_USERS_REQUEST:
    case SUBSCRIBE_REQUEST:
      return set("subscribersLoading", true, state);
    case LOAD_NOTIFICATION_USERS_SUCCESS:
      return flow(
        set("subscribersLoading", false),
        set("subscribers", action.response)
      )(state);

    case SUBSCRIBE_REQUEST:
      return flow(set("subscribersLoading", false))(state);
    case SET_ADDITIONAL_ANSWER:
      return set(
        "additionalAnswers",
        {
          ...state.additionalAnswers,
          [action.payload.key]: action.payload.data,
        },
        state
      );
    default:
      return state;
  }
};
