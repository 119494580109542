import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import {
  getCompanyUsers,
  getLoading,
  getHasCSTRole,
  getHasAdminRole,
  getHasClientAdminRole,
  getHasSuperAdminRole,
  getSearchTerm,
  getUserType,
} from "../store/users/selectors";
import { StyledDataTable, darkTheme } from "./TableStyles";
import {
  getUsersForCompany,
  deleteUser,
  setSearchTerm,
  resetPasswordRemote,
  setUserType,
} from "../store/users/actions";
import noContractors from "../assets/images/no-contractors.svg";
import { Empty } from "../components/Elements";
import Loader from "../components/Loader";
import Button from "../components/Button";
import styled from "styled-components";
import FormField from "../components/FormField";

const theme = {
  ...darkTheme,
  rows: {
    hoverBackgroundColor: "#ddd",
    fontSize: "16px",
  },
};

const TypeSelector = styled.select`
  height: 39px;
  margin-top: 20px;
  font-size: 16px;
  border: 1px solid #ccc;
`;

const SearchWrapper = styled.div`
  width: 400px;
  margin-top: 20px;
`;

const Search = styled(FormField)`
  max-width: 300px;
  margin-top: 20px;
`;

function UsersForCompany({ companyId, urlStub, history }) {
  const dispatch = useDispatch();
  const users = useSelector((state) => getCompanyUsers(state, companyId));
  const loading = useSelector(getLoading);
  const isCST = useSelector(getHasCSTRole);
  const isAdmin = useSelector(getHasAdminRole);
  const isClientAdmin = useSelector(getHasClientAdminRole);
  const isSuperAdmin = useSelector(getHasSuperAdminRole);

  const admin = isAdmin || isClientAdmin || isSuperAdmin;

  const searchTerm = useSelector(getSearchTerm);

  const [activeUsers, setActiveUsers] = useState([]);

  const userType = useSelector(getUserType);

  const [resetting, setResetting] = useState({});

  useEffect(() => {
    dispatch(getUsersForCompany(companyId, userType));
  }, [userType]);

  useEffect(() => {
    if (isCST) {
      dispatch(setUserType("contractor"));
    }
  }, [isCST]);

  useEffect(() => {
    const searchTermLower = searchTerm && searchTerm.toLowerCase();

    setActiveUsers(
      users.filter((user) => {
        if (searchTerm) {
          let test = true;

          test =
            test &&
            (user.email.toLowerCase().includes(searchTermLower) ||
              user.first_name.toLowerCase().includes(searchTermLower) ||
              user.last_name.toLowerCase().includes(searchTermLower));

          return test;
        } else {
          return true;
        }
      })
    );
  }, [users, searchTerm]);

  const doReset = async (id) => {
    setResetting({ ...resetting, [id]: true });
    await dispatch(resetPasswordRemote(id));
    setResetting({ ...resetting, [id]: false });
  };

  const deleteRow = (row) => {
    const options = {
      title: "Are you sure?",
      message: `Are you sure you want to delete ${row.first_name} ${row.last_name} It's not possible to reverse this operation`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(deleteUser(row.id, companyId));
          },
        },
        {
          label: "No",
        },
      ],
    };
    confirmAlert(options);
  };

  const columns = [
    {
      name: "Name",
      cell: (row) => {
        return `${row.first_name} ${row.last_name}`;
      },
    },
    {
      name: "Email",
      selector: "email",
    },
    {
      cell: (row) => (
        <Button
          size="reduced"
          onClick={() => doReset(row.id)}
          loading={resetting[row.id.toString()]}
          loadingText="Test"
        >
          Reset
        </Button>
      ),
      button: true,
    },
    {
      cell: (row) => (
        <Button
          action
          type="danger"
          size="reduced"
          onClick={() => deleteRow(row)}
          loadingText=""
        >
          Delete
        </Button>
      ),
      button: true,
    },
  ];

  const handleRowClicked = (row) =>
    history.push(`${urlStub}/${companyId}/users/${row.id}`);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {!isCST && (
            <TypeSelector
              value={userType}
              onChange={(e) => dispatch(setUserType(e.target.value))}
            >
              {!isCST && <option value="user">Standard Users</option>}
              {!isCST && <option value="sds">SDS Users</option>}
              {isCST || admin ? <option value="EMPTY">No Role</option> : null}
              {isCST || admin ? <option value="cst">CST</option> : null}
              {isCST || admin ? (
                <option value="contractor">Contractor (Direct)</option>
              ) : null}
            </TypeSelector>
          )}
          <SearchWrapper>
            <Search
              placeholder="Search"
              type="text"
              changeMonitor={(value) => dispatch(setSearchTerm(value))}
              className="search"
              value={searchTerm}
            />
          </SearchWrapper>

          {users.length ? (
            <span>
              <StyledDataTable
                pagination
                customTheme={theme}
                columns={columns}
                data={activeUsers || []}
                fixedHeader
                highlightOnHover
                fixedHeaderScrollHeight="100%"
                onRowClicked={handleRowClicked}
              />
            </span>
          ) : (
            <Empty>
              <img src={noContractors} />
              <h2>You've not added any users yet</h2>
              <p>Click 'New User' above to create one</p>
            </Empty>
          )}
        </div>
      )}
    </div>
  );
}

export default UsersForCompany;
