import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getNotesForQuestion } from "../store/assessment/selectors";
import { addNote } from "../store/assessment/actions";
import moment from "moment";
import styled from "styled-components";
import Button from "./Button";

const Notes = styled.div`
  background-color: #f9f9f9;
  padding: 10px;
  margin-bottom: 20px;
`;

const NewNote = styled.textarea`
  padding: 10px;
  width: 100%;
  height: 100px;
  font-size: 16px;
  box-sizing: border-box;
`;

const ActionTable = styled.table`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 20px;
  th,
  td {
    padding: 5px;
    font-size: 14px;
  }
  th {
    font-weight: bold;
    background-color: #ddd;
  }

  td {
    background-color: #eee;
  }
`;

function QuestionNotes(props) {
  const { questionId, readonly } = props;
  const notes = useSelector((state) => getNotesForQuestion(questionId, state));
  const dispatch = useDispatch();
  const [newNote, setNewNote] = useState("");

  const addNewNote = () => {
    dispatch(addNote(questionId, newNote));
    setNewNote("");
  };

  return (
    <Notes>
      {notes && notes.length > 0 && (
        <ActionTable>
          <thead>
            <th width="150">Date</th>
            <th width="180">User</th>
            <th>Note</th>
          </thead>
          {notes.map((note) => (
            <tr>
              <td>{moment(note.datetime).format("DD/MM/YYYY HH:mm")}</td>
              <td>{note.name}</td>
              <td>{note.note}</td>
            </tr>
          ))}
        </ActionTable>
      )}
      {notes && !notes.length && <div>No notes</div>}
      {!readonly && (
        <div>
          <NewNote
            placeholder="Type new note here"
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
          ></NewNote>
          <Button
            noFullWidth
            onClick={addNewNote}
            disabled={newNote.length < 5}
          >
            Add Note
          </Button>
        </div>
      )}
    </Notes>
  );
}

export default QuestionNotes;
