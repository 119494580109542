import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { getUploadLoading } from "../store/users/selectors";
import { uploadBulkFile } from "../store/users/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { ContentArea } from "../components/Elements";
import Loader from "../components/Loader";
import { getUserToken } from "../store/users/selectors";
import FormField from "../components/FormField";
import FormRow from "../components/FormRow";
import Button from "../components/Button";
import { StyledDataTable, darkTheme } from "../components/TableStyles";

const SettingsWrapper = styled.div`
  background-color: #fff;
  margin: 0px 20px 20px 20px;
  border-radius: 10px;
  padding: 20px;
`;

const LeftRight = styled.div`
  display: flex;

  .left {
    flex: 1;
    box-sizing: border-box;
    padding-right: 30px;
  }

  .right {
    flex-basis: 300px;
  }
`;

const Template = styled.div`
  background-color: #eee;
  border-radius: 6px;
  padding: 20px;

  h3 {
    margin-top: 0;
  }
`;

const Icon = styled.div`
  background-color: #aaa;
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  color: #fff;

  transition: background-color 200ms ease-in;

  &:hover {
    background-color: #777;
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 40px;
  color: #fff;
  display: inline-block;
  margin-right: 10px;
`;

const ConfirmForm = styled.div`
  background-color: #eee;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-sizing: border-box;
  position: relative;
`;

const Mask = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
`;

const ValidationError = styled.div`
  color: red;
`;

const companyColumns = [
  {
    name: "Client Name",
    selector: "name",
    maxWidth: "200px",
  },
  {
    name: "External Reference",
    selector: "external_reference",
    maxWidth: "200px",
  },
  {
    name: "New/Existing",
    cell: (row) => (row.new ? "New" : "Existing"),
    maxWidth: "200px",
  },
];

const Completed = styled.div`
  color: green;
  font-weight: bold;
`;

const conditionalRowStyles = [
  {
    when: (row) => row.validations.length > 0,
    style: {
      backgroundColor: "#ffc6c6",
      color: "#830b0b",
    },
  },
];

const ContractorWrapper = styled.div`
  margin-left: 30px;
  border-left: 1px solid #e4e4e4;
  padding-left: 20px;
  border-bottom: 1px solid #e4e4e4;
`;

function ContractorTable(row) {
  return (
    <ContractorWrapper>
      <StyledDataTable
        customTheme={darkTheme}
        columns={[
          {
            name: "External Reference",
            selector: "external_reference",
          },
          {
            name: "First Name",
            selector: "first_name",
          },
          {
            name: "Last Name",
            selector: "last_name",
          },
          {
            name: "Email",
            selector: "email",
          },
          {
            name: "Phone Number",
            selector: "phone_number",
          },
          {
            name: "Ltd Company Name",
            selector: "ltd_company_name",
          },
          {
            name: "Ltd Company Number",
            selector: "ltd_company_number",
          },
          {
            name: "New/Existing",
            cell: (row) => (row.existing ? "Existing" : "New"),
          },
          {
            name: "Errors",
            cell: (row) =>
              row.validations.length > 0 ? (
                <ul>
                  {row.validations.map((validation) => (
                    <li>{validation}</li>
                  ))}
                </ul>
              ) : (
                "None"
              ),
          },
        ]}
        data={row.data.children}
        fixedHeader
        fixedHeaderScrollHeight="100%"
        title="Contractors"
        conditionalRowStyles={conditionalRowStyles}
      />
    </ContractorWrapper>
  );
}

const isExpanded = (row) => {
  return true;
};

const messages = [
  "Please Wait...",
  "Still uploading...",
  "Not much longer...",
  "This is taking a little longer than usual",
];

function Uploads() {
  const dispatch = useDispatch();
  const loading = useSelector(getUploadLoading);

  useEffect(() => {}, []);
  const userToken = useSelector(getUserToken);

  const [file, setFile] = useState(null);

  const [validatedData, setValidatedData] = useState([]);

  const [overwriteDuplicates, setOverwriteDuplicates] = useState(false);

  const [loadingMessage, setLoadingMessage] = useState(messages[0]);

  const [selectedMessage, setSelectedMessage] = useState(0);

  const [validateMessage, setValidateMessage] = useState("");

  const [complete, setComplete] = useState(false);

  const selectedRef = useRef(selectedMessage);
  selectedRef.current = selectedMessage;

  const changeMessage = () => {
    const length = messages.length;
    let numberToUse;

    if (selectedRef.current + 1 < length) {
      numberToUse = selectedRef.current + 1;
      setSelectedMessage(numberToUse);
      setLoadingMessage(messages[numberToUse]);
    }
  };

  useEffect(() => {
    let timer;
    if (validatedData.length) {
      if (loading) {
        timer = setInterval(changeMessage, 20000);
      } else {
        setSelectedMessage(0);
        setLoadingMessage(messages[0]);
        clearInterval(timer);
      }
    }
  }, [loading]);

  const validate = async (e) => {
    const response = await doUpload(e, true);

    if (!response) {
      return;
    }

    let finalData = [];
    const types = ["existing", "new"];

    types.forEach((type) => {
      Object.keys(response[type]).forEach((company) => {
        let companyRec = { name: company !== "root" ? company : "Direct" };
        if (type === "new") {
          companyRec.new = true;
        }
        companyRec.children = response[type][company];
        finalData.push(companyRec);
      });
    });

    setValidatedData(finalData);
  };

  const confirm = async (e) => {
    const response = await doUpload(e, false);

    setComplete(true);
  };

  const doUpload = async (e, validate) => {
    e.preventDefault();
    let formData = new FormData();

    formData.append("file", file);

    let response;

    setValidateMessage("");
    try {
      response = await dispatch(
        uploadBulkFile(formData, validate, overwriteDuplicates)
      );
    } catch (e) {
      return setValidateMessage(e.data.body.errors.join(", "));
    }

    return response;
  };

  return (
    <ContentArea>
      <SettingsWrapper>
        <h2>Uploads</h2>
        <LeftRight>
          <div className="left">
            <h3>File Upload</h3>
            <FormRow cols={2}>
              <FormField
                placeholder="Choose File"
                type="file"
                changeMonitor={setFile}
                label="File to upload"
              />
            </FormRow>
            <FormRow>
              <Button noFullWidth={true} loading={loading} onClick={validate}>
                Validate File
              </Button>
            </FormRow>
            {validateMessage && (
              <FormRow>
                <ValidationError>{validateMessage}</ValidationError>
              </FormRow>
            )}
            {validatedData && validatedData.length !== 0 && (
              <FormRow cols={2}>
                <ConfirmForm>
                  {loading && (
                    <Mask>
                      <Loader text={loadingMessage}></Loader>
                    </Mask>
                  )}
                  <h2>Confirm Data</h2>
                  <p>
                    Check the data below and once you're ready, click 'Complete
                    Upload' to insert the data into the system.
                  </p>
                  <p>
                    Any records marked in red will not be uploaded unless you
                    make the required changes and re-select the file for upload
                    above.
                  </p>
                  <p>
                    <strong>
                      Please Note: This operation cannot be reversed.
                    </strong>
                  </p>

                  <FormRow>
                    <FormField
                      name="overwrite_duplicates"
                      label="Overwrite Duplicates"
                      type="checkbox"
                      inputType="checkbox"
                      value={overwriteDuplicates}
                      changeMonitor={(value) => setOverwriteDuplicates(value)}
                      helpText="If ticked, any rows that are marked as 'existing' will be overwritten, if you leave this option un-ticked, these records will be ignored."
                    />
                  </FormRow>
                  <FormRow>
                    <Button
                      noFullWidth={true}
                      loading={loading}
                      onClick={confirm}
                    >
                      Upload Records
                    </Button>
                  </FormRow>
                  {complete && (
                    <FormRow>
                      <Completed>Upload Complete</Completed>
                    </FormRow>
                  )}
                </ConfirmForm>
              </FormRow>
            )}
          </div>
          <div className="right">
            <Template>
              <h3>File Template</h3>
              <Icon
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_BASE_URL}/Contractors/csv-template?access_token=${userToken}&csv=true`
                  )
                }
              >
                <StyledFontAwesomeIcon icon={faFileCsv} />
                Template.csv
              </Icon>
              <p>
                Download this file and open in your favourite editor such as
                Excel or Numbers. Leave the first row alone as these are the
                fields the system will need to read when uploading.
              </p>
              <p>
                {" "}
                You can then delete the other rows and add in your own data.
              </p>
            </Template>
          </div>
        </LeftRight>
        {validatedData && validatedData.length !== 0 && (
          <div>
            <StyledDataTable
              progressPending={loading}
              pagination
              customTheme={darkTheme}
              columns={companyColumns}
              data={validatedData}
              highlightOnHover
              title="Validated Data"
              expandableRows={true}
              expandableRowsComponent={<ContractorTable />}
              expandableRowExpanded={isExpanded}
            />
          </div>
        )}
      </SettingsWrapper>
    </ContentArea>
  );
}

export default Uploads;
