import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { getLoading, getContractorById } from "../store/contractors/selectors";

import {
  loadClientsAndSuppliers,
  addNewClient,
  addNewSupplier,
  createEngagement,
  saveEngagement,
  submitAssessment,
  clearReportingData,
  loadRoleAssessments,
  submitOverride,
} from "../store/assessment/actions";
import {
  getSuppliers,
  getClients,
  getClientsSuppliersLoading,
  getRoleAssessments,
} from "../store/assessment/selectors";
import { loadContractor } from "../store/contractors/actions";
import {
  SplitView,
  TitleArea,
  ContentArea,
  Detail,
  Wrapper,
} from "../components/Elements";
import {
  getOutOfCredits,
  getHasFeature,
  getUser,
  getNearestAgency,
} from "../store/users/selectors";
import {
  loadClients,
  loadIncludesValidationTool,
} from "../store/clients/actions";
import {
  getClientById,
  getIncludesValidationTool,
} from "../store/clients/selectors";
import Loader from "../components/Loader";
import CRUDForm from "../components/CRUDForm";
import Form from "../components/Form";
import FormField from "../components/FormField";
import FormRow from "../components/FormRow";
import Assessment from "../components/Assessment";
import Button from "../components/Button";
import SignOffSelector from "../components/SignOffSelector";
import { reviewDark, reviewLight } from "../styles/colors";
import AssignTags from "../components/AssignTags";
import { Tabs, tabStyles } from "../components/Tabs";
import Notifications from "../components/Notifications";

const ReviewerPanel = styled.div`
  text-align: center;
  margin: 0px 20px 20px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid ${reviewDark};
  background-color: ${reviewLight};
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const TabsWrapper = styled.div`
  margin: 0px -20px 0px -20px;
`;

const StyledTabs = styled(Tabs)`
  margin-left: 0px;
  margin-right: 0px;
`;

const Tab = styled.a`
  ${tabStyles}
  text-decoration: none;
`;

const TabContent = styled.div`
  background-color: #fff;
  margin: 20px 20px 20px 20px;
  border-radius: 10px;
  padding: 20px;
`;

const Select = styled.select`
  height: 39px;
  margin-right: 20px;
  font-size: 16px;
  outline: none;
  border: 1px solid #ccc;
`;

const TagsWrapper = styled.div`
  margin: 0 20px 20px 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  h2 {
    margin-top: 0;
  }
`;

function CreateAssessment({ match, history }) {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("DETAIL");
  const loading = useSelector(getLoading);
  const hasReviewFeature = useSelector((state) =>
    getHasFeature(state, "feature_review")
  );
  const hasDisseminationFeature = useSelector((state) =>
    getHasFeature(state, "feature_dissemination")
  );
  const hideRate = useSelector((state) => getHasFeature(state, "hide_rate"));
  const recordId = match.params.contractorId;
  const mode = match.params.mode;
  const companyId = match.params.companyId;
  const isNew = mode === "new";
  const [dataFromForm, setDataFromForm] = useState({});
  const [dirty, setDirty] = useState(false);
  const nearestAgency = useSelector(getNearestAgency);
  const user = useSelector(getUser);

  const includeValidationTool = useSelector(getIncludesValidationTool);

  const record = useSelector((state) => getContractorById(state, recordId));
  const [reviewers, setReviewers] = useState(null);

  const engagement =
    record &&
    record.engagements &&
    record.engagements.find((engagement) => engagement.id === Number(mode));

  const emptyState = {
    start_date: new Date(),
    completion_date: new Date(),
    client_id: "",
    supplier_id: "",
    day_rate: null,
    dissemination: "NO",
    contractor_email: "",
    role_name: "",
    agency: "",
    validation: null,
  };

  useEffect(() => {
    if (record) {
      const recordToUse = record || {};
      const engagement =
        recordToUse.engagements &&
        recordToUse.engagements.find(
          (engagement) => engagement.id === Number(mode)
        );

      if (
        engagement &&
        engagement.review &&
        engagement.review.approvals &&
        engagement.reviewers
      ) {
        setReviewers(
          engagement.review.approvals.map((approval) => {
            var match = engagement.reviewers.find(
              (er) => er.user_id === approval.user_id
            );

            return {
              ...approval,
              user_selected: match ? match.user_selected : false,
            };
          })
        );
      } else {
        setReviewers([]);
      }

      setFormData({
        ...emptyState,
        ...engagement,
        agency: nearestAgency,
      });
    }
  }, [record, engagement]);

  useEffect(() => {
    setFormData({
      ...formData,
      agency: nearestAgency,
    });
  }, [nearestAgency]);

  useEffect(() => {
    dispatch(loadClientsAndSuppliers());

    dispatch(clearReportingData());

    dispatch(loadContractor(recordId, companyId, mode));

    if (mode === "new") {
      dispatch(loadRoleAssessments(companyId));
    }

    dispatch(loadClients());

    dispatch(loadIncludesValidationTool(companyId));
  }, []);

  const isReviewer =
    user &&
    reviewers &&
    reviewers.find(
      (reviewer) =>
        reviewer.user_id === user.userId ||
        Number(reviewer.assigned) === Number(user.userId)
    );

  const isManualReviewer =
    user &&
    reviewers &&
    reviewers.find(
      (reviewer) => Number(reviewer.assigned) === Number(user.userId)
    );

  const [formData, setFormData] = useState(emptyState);

  const review = formData && formData.review;

  const inReview = review && review.status !== "CLOSED";

  const assessment = formData && formData.assessment;

  const roleAssessments = useSelector(getRoleAssessments).filter(
    (roleAssessment) => !!roleAssessment.assessment
  );

  const [selectedRoleAssessment, setSelectedRoleAssessment] = useState(null);

  const [roleBasedAssessmentId, setRoleBasedAssessmentId] = useState(null);

  const company = useSelector((state) => getClientById(state, companyId));

  const companyName = company && company.name;

  useEffect(() => {
    if (engagement && !engagement.assessment && engagement.role_id) {
      setRoleBasedAssessmentId(engagement.role_id);
    }
  }, [engagement]);

  const applyRoleBasedAssessment = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedRoleAssessment) {
      //Get the right role
      let role = roleAssessments.find(
        (roleAssessment) => roleAssessment.id === Number(selectedRoleAssessment)
      );

      if (role) {
        setRoleBasedAssessmentId(role.assessment.id);
        setFormData({
          ...formData,
          start_date: new Date(role.start_date),
          completion_date: new Date(role.completion_date),
          day_rate: role.day_rate,
        });
      }
    }
  };

  const roleBased = recordId === "rolebased";

  const sendForm = async (formData) => {
    if (typeof formData.client_id === "object") {
      formData.client_id = formData.client_id.value;
    }

    if (typeof formData.supplier_id === "object") {
      formData.supplier_id = formData.supplier_id.value;
    }

    if (!formData.validation) {
      formData.role_id = roleBasedAssessmentId;
    }
    formData.day_rate = Number(formData.day_rate);

    formData.reviewers = reviewers;

    let response;

    if (isNew) {
      formData.contractor_id = recordId;
      if (recordId === "rolebased") {
        formData.contractor_id = -1;
        formData.company_id = companyId;
      }

      response = await dispatch(createEngagement(formData));

      dispatch(loadContractor(recordId, companyId, mode));

      const area = roleBased ? "roles" : "contractors";

      history.push(
        `/main/${area}/assessment/${companyId}/${recordId}/${response.id}`
      );
    } else {
      response = await dispatch(saveEngagement(formData));

      dispatch(loadContractor(recordId, companyId, mode));
    }
    setDirty(false);
  };

  const completeSendingAssessment = async (
    assessmentId,
    answers,
    reopen,
    notes
  ) => {
    const response = await dispatch(
      submitAssessment(
        recordId,
        assessmentId,
        formData.id,
        answers.map((answer) => ({ id: answer })),
        "CLIENT",
        reopen,
        notes
      )
    );

    dispatch(loadContractor(recordId, companyId, mode));

    return response.id;
  };

  const sendOverride = async (assessmentId, newResult, reason) => {
    await dispatch(submitOverride(assessmentId, newResult, reason));
    dispatch(loadContractor(recordId, companyId, mode));
  };

  const sendAssessment = async (assessmentId, answers, reopen, notes) => {
    return await completeSendingAssessment(
      assessmentId,
      answers,
      reopen,
      notes
    );
  };

  const exclude =
    roleBased ||
    (dataFromForm.dissemination === "YES" &&
      formData &&
      (!formData.assessment || formData.assessment.status !== "COMPLETE"))
      ? "CONTRACTOR"
      : null;

  return (
    <Wrapper>
      {(!isNew && !record) || !record ? (
        <Loader />
      ) : (
        <Wrapper>
          <TitleArea>
            <div className="title">
              <h1>
                {roleBased
                  ? `Role Based Assessment for: ${companyName}`
                  : "IR35 Assessment"}
              </h1>
            </div>
            <div className="controls" />
          </TitleArea>
          <ContentArea>
            {assessment && isReviewer && inReview && (
              <ReviewerPanel>
                <StyledFontAwesomeIcon icon={faEye} />
                You are a reviewer of this assessment
              </ReviewerPanel>
            )}
            {assessment && !isReviewer && inReview && (
              <ReviewerPanel>
                <StyledFontAwesomeIcon icon={faEye} />
                This assessment is under review
              </ReviewerPanel>
            )}
            <SplitView>
              <div className="left">
                <TagsWrapper>
                  <h2>
                    {roleBased ? "Role Assessment Tags" : "Assessment Tags"}
                  </h2>
                  <AssignTags
                    masked={isNew}
                    companyId={companyId}
                    currentCompanyId={companyId}
                    entityId={formData && formData.id && formData.id}
                    entityType={roleBased ? "roleAssessment" : "assessment"}
                  />
                </TagsWrapper>
                <CRUDForm
                  emptyState={emptyState}
                  formData={formData}
                  loading={loading}
                  recordId={recordId}
                  onSubmit={sendForm}
                  updater={(newData) => {
                    setDataFromForm(newData);
                  }}
                  onDirty={(value) => {
                    setDirty(value);
                  }}
                  validationRules={{
                    validation: [
                      {
                        excludeDefault: true,
                        fn: (value) => {
                          if (value === null || value === undefined) {
                            return false;
                          }
                          return true;
                        },
                        msg: "This field is required",
                      },
                    ],
                    contractor_email: [
                      {
                        canned: "email",
                      },
                    ],
                  }}
                >
                  <Form internal={true}>
                    <TabsWrapper>
                      <StyledTabs>
                        <Tab
                          onClick={(e) => {
                            e.preventDefault();
                            setActiveTab("DETAIL");
                          }}
                          className={activeTab === "DETAIL" ? "active" : ""}
                        >
                          Detail
                        </Tab>
                        {!isNew && (
                          <Tab
                            onClick={(e) => {
                              e.preventDefault();
                              setActiveTab("NOTIFICATIONS");
                            }}
                            className={
                              activeTab === "NOTIFICATIONS" ? "active" : ""
                            }
                          >
                            Notifications
                          </Tab>
                        )}
                      </StyledTabs>
                    </TabsWrapper>
                    {activeTab === "DETAIL" && (
                      <div>
                        {isNew &&
                          !roleBased &&
                          roleAssessments &&
                          roleAssessments.length !== 0 &&
                          !dataFromForm.validation && (
                            <>
                              <h2>Apply role-based assessment</h2>
                              <Select
                                onChange={(e) =>
                                  setSelectedRoleAssessment(e.target.value)
                                }
                              >
                                <option value="">Choose</option>
                                {roleAssessments.map((roleAssessment) => (
                                  <option value={roleAssessment.id}>
                                    {roleAssessment.role_name}
                                  </option>
                                ))}
                              </Select>
                              <Button
                                noFullWidth={true}
                                noBind
                                onClick={(e) => {
                                  applyRoleBasedAssessment(e);
                                }}
                              >
                                Apply
                              </Button>
                            </>
                          )}
                        {!roleBased && (
                          <Detail>
                            <h2>Contractor Details</h2>
                            <p>
                              <strong>Name:</strong> {record.first_name}{" "}
                              {record.last_name}
                            </p>
                            <p>
                              <strong>Ltd Company Name:</strong>{" "}
                              {record.ltd_company_name}
                            </p>
                          </Detail>
                        )}
                        <h2>Engagement Details</h2>
                        {roleBased && (
                          <FormRow cols={1}>
                            <FormField
                              type="text"
                              name="role_name"
                              label="Role name / reference"
                            />
                          </FormRow>
                        )}
                        <FormRow cols={2}>
                          <FormField
                            inputType="date"
                            name="start_date"
                            label="Start Date"
                          />
                          <FormField
                            inputType="date"
                            name="completion_date"
                            label="End Date"
                          />
                        </FormRow>
                        {!hideRate && (
                          <FormRow cols={2}>
                            <FormField
                              helpText="This is the gross day rate paid to the contractor before any deductions"
                              name="day_rate"
                              label="Day Rate"
                              type="number"
                            />
                            <FormField
                              helpText="The agency supplying the worker, if applicable"
                              name="agency"
                              label="Agency"
                              optional={true}
                            />
                          </FormRow>
                        )}
                        {includeValidationTool && !roleBased && (
                          <FormRow cols={2}>
                            <FormField
                              disabled={formData && formData.assessment}
                              helpText="Whether to carry out a full assessment or validate for insurance purposes"
                              name="validation"
                              label="Type of assessment"
                              inputType="splitbutton"
                              options={[
                                { display: "Full Assessment", value: false },
                                {
                                  display: "Insurance Validation",
                                  value: true,
                                },
                              ]}
                            />
                            {dataFromForm.validation && (
                              <FormField
                                helpText="The name of the organisation who provided the original SDS"
                                name="sds_provider"
                                label="Original SDS provider"
                              />
                            )}
                          </FormRow>
                        )}

                        {!dataFromForm.validation &&
                          hasDisseminationFeature &&
                          !isReviewer &&
                          !(formData && formData.assessment) &&
                          !roleBased && (
                            <>
                              <h2>Contractor Questions</h2>
                              <FormRow cols={1}>
                                <FormField
                                  name="dissemination"
                                  label="Question Dissemination"
                                  inputType="radio"
                                  helpText="You can either fill the contractor specific answers in your self or you can send them direct to the contractor.  You'll be able to view and edit the answers later if necessary."
                                  layout="vertical"
                                  disabled={formData && formData.assessment}
                                  options={[
                                    {
                                      value: "NO",
                                      name:
                                        "I will answer all the questions myself",
                                    },
                                    {
                                      value: "YES",
                                      name:
                                        "I want to send the contractor questions specific to them",
                                    },
                                  ]}
                                />
                              </FormRow>
                              {dataFromForm.dissemination === "YES" && (
                                <FormRow cols={2}>
                                  <strong>
                                    We'll send the questions to:{" "}
                                    {record.email || "Not Set"}{" "}
                                  </strong>
                                </FormRow>
                              )}
                            </>
                          )}

                        {hasReviewFeature && (
                          <>
                            <h2>Sign-off Review</h2>
                            <p>
                              You can get external review and sign off by adding
                              reviewers to this assessment. They will be emailed
                              automatically once the assessment is complete.
                            </p>
                            <SignOffSelector
                              noManual={dataFromForm.validation}
                              companyId={companyId}
                              linkedUsers={reviewers}
                              setter={setReviewers}
                            />
                          </>
                        )}

                        <FormRow cols={2}>
                          <Button noFullWidth={true}>Save</Button>
                        </FormRow>
                      </div>
                    )}
                    {activeTab === "NOTIFICATIONS" && (
                      <div>
                        <h2>Notifications</h2>
                        <p>
                          Choose which people you want to be notified every time
                          there is an update or change for this assessment. Only
                          these people will receive notifications.
                        </p>
                        <Notifications
                          assessmentId={engagement && engagement.id}
                        />
                      </div>
                    )}
                  </Form>
                </CRUDForm>
              </div>
              <div className="right">
                <Assessment
                  assessmentType={
                    dataFromForm.validation !== null
                      ? dataFromForm.validation
                        ? "SHORT"
                        : "FULL"
                      : ""
                  }
                  external={false}
                  contractorId={recordId}
                  disabled={isNew || dirty}
                  assessmentResult={formData && formData.assessment}
                  onSubmit={sendAssessment}
                  exclude={exclude}
                  dissemination={dataFromForm.dissemination === "YES"}
                  inReview={inReview}
                  isManualReviewer={isManualReviewer}
                  isReviewer={isReviewer}
                  reviewers={reviewers}
                  review={review}
                  engagement={engagement}
                  roleAssessmentId={roleBasedAssessmentId}
                  showDisclaimer={formData && !formData.assessment}
                  onOverride={sendOverride}
                  additionalConditional={{}}
                />
              </div>
            </SplitView>
          </ContentArea>
        </Wrapper>
      )}
    </Wrapper>
  );
}

export default CreateAssessment;
