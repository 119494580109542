import { get, getOr } from "lodash/fp";

export const getLoading = (state) => {
  return get("contractors.loading", state);
};
export const getContractorById = (state, id) => {
  const contractors = get("contractors.list", state);

  id = Number(id === "rolebased" ? -1 : id);

  return contractors
    ? contractors.find((contractor) => contractor.id === id)
    : null;
};

export const getContractors = (state) => {
  return get("contractors.list", state);
};

export const getStandaloneSummary = (state) => {
  return getOr({}, "contractors.standaloneSummary", state);
};

export const getTotalRows = (state) => {
  return get("contractors.totalRows", state);
};

export const getCurrentStandalonePage = (state) => {
  return get("contractors.standalonePage", state);
};

export const getCurrentPage = (state) => {
  return get("contractors.page", state);
};

export const getPerPage = (state) => {
  return get("contractors.perPage", state);
};

export const getStandalonePerPage = (state) => {
  return get("contractors.standalonePerPage", state);
};

export const getSearchTerm = (state) => {
  return get("contractors.searchTerm", state);
};

export const getStandaloneSearchTerm = (state) => {
  return get("contractors.standaloneSearchTerm", state);
};

export const getCanShareLeadsForCompany = (state) => {
  return get("contractors.canShareLeads", state);
};
