import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTag, editTag } from "../store/tags/actions";
import { getTagsLoading, getTagsError } from "../store/tags/selectors";
import Loader from "./Loader";
import Button from "./Button";
import Tag from "./Tag";
import styled from "styled-components";

const TagListArea = styled.div`
  position: relative;
  ${(props) => (props.noMargin ? "padding: 0;" : "padding 20px")};
`;

const TagSearch = styled.div`
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;

  input {
    border: 0;
    border-radius: 3px;
    font-size: 16px;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
  }
`;

const ButtonWrap = styled.div`
  margin-bottom: 10px;
`;

const TagListElement = styled.div`
  display: block;
`;

const LoadMask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
`;

const Empty = styled.div`
  background-color: #eee;
  display: inline-block;
  margin-right: 6px;
  margin-bottom: 6px;
  border-radius: 3px;
  padding: 4px 6px;
  text-transform: uppercase;
  font-size: 12px;
`;

function TagList({
  tags,
  removable,
  onRemove,
  onClick,
  enableSearch,
  onCreate,
  companyId,
  noMargin,
  showLoadingStatus,
  selectedTags = [],
}) {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [tagsToRender, setTagsToRender] = useState([]);
  const loading = useSelector(getTagsLoading);
  const error = useSelector(getTagsError);

  const doCreate = async () => {
    const newTag = await dispatch(
      createTag({
        company_id: companyId,
        text: searchTerm,
        color: "grey",
      })
    );
    setSearchTerm("");

    onCreate(newTag);
  };

  useEffect(() => {
    if (searchTerm) {
      setTagsToRender(
        tags.filter(
          (tag) => tag.text.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        )
      );
    } else {
      setTagsToRender(tags);
    }
  }, [searchTerm]);

  useEffect(() => {
    setTagsToRender(tags || []);
  }, [tags]);

  return (
    <TagListArea noMargin={noMargin}>
      {loading && (
        <LoadMask>
          <Loader></Loader>
        </LoadMask>
      )}
      {enableSearch && (
        <TagSearch>
          <input
            type="text"
            onChange={(event) => setSearchTerm(event.target.value)}
            value={searchTerm}
            placeholder="Search"
          ></input>
        </TagSearch>
      )}
      {onCreate && searchTerm && (
        <ButtonWrap>
          <Button noFullWidth={true} onClick={doCreate} size="reduced">
            Create {searchTerm}
          </Button>
        </ButtonWrap>
      )}
      <TagListElement>
        {tagsToRender.map((tag) => (
          <Tag
            showLoadingStatus={showLoadingStatus}
            id={tag.id}
            text={tag.text}
            color={tag.color}
            removable={removable}
            onRemove={onRemove}
            onClick={onClick}
            selected={selectedTags.includes(tag.id)}
          />
        ))}
      </TagListElement>
      {!onCreate && searchTerm && tagsToRender.length === 0 && (
        <div>No tags found for '{searchTerm}'</div>
      )}
      {!onCreate && !searchTerm && tagsToRender.length === 0 && (
        <Empty>No tags linked</Empty>
      )}
      {onCreate && !searchTerm && tagsToRender.length === 0 && (
        <Empty>You can create a tag by typing its name above</Empty>
      )}
    </TagListArea>
  );
}

export default TagList;
