import React, { useState, useRef, useEffect, useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormField from "../../FormField";
import styled from "styled-components";
import FormRow from "../../FormRow";
import Validation from "../../FormValidation";
import moment from "moment";

const providers = [
  { value: "Caunce O'Hara", name: "Caunce O'Hara" },
  { value: "Champion Aberdeen", name: "Champion Aberdeen" },
  { value: "Hiscox", name: "Hiscox" },
  { value: "Kingsbridge", name: "Kingsbridge" },
  { value: "Markel Direct", name: "Markel Direct" },
  { value: "Qdos", name: "Qdos" },
  { value: "Randell Dorling", name: "Randell Dorling" },
  { value: "Simply Business", name: "Simply Business" },
  { value: "Other", name: "Other" },
];

const SubForm = styled.div`
  background-color: #efefef;
  padding: 20px;
  border-radius: 5px;
  margin: 10px 0 20px;
  max-width: 400px;
  border: 1px solid #ddd;
  h3 {
    margin-top: 0;
  }
`;

const transformers = {
  expiry: (value) => {
    const today = moment(new Date());
    const month = today.month();
    const givenMonth = Number(value);
    let year;
    if (givenMonth < month) {
      year = today.year() + 1;
    } else {
      year = today.year();
    }

    const format = moment(new Date())
      .date(15)
      .month(givenMonth - 1)
      .year(year)
      .format("YYYY-MM-DD");

    return format;
  },
};

const translators = {
  expiry: (value) => {
    const date = moment(value);
    return date.month() + 1;
  },
};

const transform = (key, value) => {
  return transformers[key] ? transformers[key](value) : value;
};

const translate = (values) => {
  const keys = Object.keys(values);
  keys.forEach((key) => {
    if (translators[key]) {
      values = { ...values, [key]: translators[key](values[key]) };
    }
  });
  return values;
};

const InsuranceElsewhere = React.forwardRef(({ selector, setAnswers }, ref) => {
  const values = useSelector((state) => translate(selector(state)));

  const [validation, setValidation] = useState({});
  const formValidation = new Validation(validation, setValidation);
  formValidation.setRules({});

  if (values.provider === "Other") {
    formValidation.monitorFields(["provider", "other", "expiry"]);
  } else {
    formValidation.monitorFields(["provider", "expiry"]);
  }

  const validate = () => {
    const validationErrors = formValidation.validateForm(values);

    if (validationErrors) {
      return Object.keys(validationErrors);
    }
    return true;
  };

  useImperativeHandle(ref, () => ({
    validate,
  }));

  const updateValue = (key, value) => {
    setAnswers({
      ...values,
      [key]: transform(key, value),
    });
    formValidation.validate(key, value, values);
  };

  return (
    <SubForm>
      <h3>Additional Information Required</h3>
      <FormRow>
        <FormField
          name="provider"
          label="Who do you hold your insurances with?"
          inputType="select"
          options={providers}
          changeMonitor={(value) => updateValue("provider", value)}
          value={values.provider}
          validation={validation.provider}
        />
      </FormRow>
      {values && values.provider === "Other" && (
        <FormRow>
          <FormField
            type="text"
            name="other"
            label="Please Specify"
            changeMonitor={(value) => updateValue("other", value)}
            value={values.other}
            validation={validation.other}
          />
        </FormRow>
      )}
      <FormRow>
        <FormField
          name="expiry"
          label="Which month does it expire?"
          inputType="select"
          options={[
            { value: "1", name: "January" },
            { value: "2", name: "February" },
            { value: "3", name: "March" },
            { value: "4", name: "April" },
            { value: "5", name: "May" },
            { value: "6", name: "June" },
            { value: "7", name: "July" },
            { value: "8", name: "August" },
            { value: "9", name: "September" },
            { value: "10", name: "October" },
            { value: "11", name: "November" },
            { value: "12", name: "December" },
          ]}
          changeMonitor={(value) => updateValue("expiry", value)}
          value={values.expiry}
          validation={validation.expiry}
        />
      </FormRow>
    </SubForm>
  );
});

export default InsuranceElsewhere;
