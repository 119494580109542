import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { getUsersForCompany } from "../store/users/actions";
import {
  getCompanyUsers,
  getLoading,
  getUser,
  getReviewerCompany,
  getCanViewSettings,
} from "../store/users/selectors";
import Loader from "./Loader";
import Button, { ActionButton } from "./Button";

const SelectorSurround = styled.div`
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 20px;
`;

const SelectorRow = styled.div`
  margin-top: 10px;
`;

const Selector = styled.select`
  height: 39px;
  width: 200px;
  margin-top: 10px;
  font-size: 16px;
  outline: none;
  border: 1px solid #ccc;
  margin-right: 10px;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

function SignOffSelector({
  companyId,
  history,
  linkedUsers,
  setter,
  noManual = false,
}) {
  const dispatch = useDispatch();
  const users = useSelector((state) => getCompanyUsers(state, companyId));
  const loggedInUser = useSelector(getUser);
  const loading = useSelector(getLoading);
  const [localUsers, setLocalUsers] = useState([]);
  const reviewerCompany = useSelector(getReviewerCompany);
  const canViewSettings = useSelector(getCanViewSettings);

  useEffect(() => {
    setLocalUsers(linkedUsers);
  }, [linkedUsers]);

  const removeItem = (remove) => {
    setter(localUsers.filter((user) => user.user_id !== remove));
  };

  const hasEmpty =
    localUsers && localUsers.filter((user) => user.user_id === 0).length;

  const appropriateUsers = users.filter(
    (user) =>
      user.roles.find((role) => role.name === "user") &&
      ((localUsers &&
        localUsers.find(
          (localUser) => localUser.user_id === loggedInUser.userId
        )) ||
        user.id !== loggedInUser.userId)
  );

  const fullLength =
    appropriateUsers && appropriateUsers.length + (reviewerCompany ? 1 : 0);

  const someNotSelected = localUsers && fullLength !== localUsers.length;

  const generateSelector = (value) => {
    const matchingUser =
      localUsers.find((localUser) => localUser.user_id === value) || {};

    return (
      <SelectorRow>
        <Selector
          disabled={value !== 0 && !matchingUser.user_selected}
          value={value}
          onChange={(e) => {
            let newValue = Number(e.target.value);
            const newToBeSet = localUsers.filter((lu) => lu.user_id !== value);
            const newUser =
              newValue === -1
                ? {
                    id: -1,
                    first_name: "Manual",
                    last_name: "Reviewer2",
                  }
                : appropriateUsers.find((user) => user.id === newValue);
            newToBeSet.push({
              user_id: newUser.id,
              user_selected: true,
              appUser: newUser,
            });

            setter(newToBeSet);
          }}
        >
          <option value="0">Choose</option>
          {appropriateUsers
            .filter((user) => {
              return !localUsers
                .filter(
                  (localUser) => Number(localUser.user_id) !== Number(value)
                )
                .find((lu) => lu.user_id === user.id);
            })
            .map((user) => {
              return (
                <option
                  value={user.id}
                >{`${user.first_name} ${user.last_name}`}</option>
              );
            })}
          {reviewerCompany &&
            !noManual &&
            !localUsers
              .filter(
                (localUser) => Number(localUser.user_id) !== Number(value)
              )
              .find((lu) => lu.user_id === -1) && (
              <option value="-1">{reviewerCompany} Review</option>
            )}
        </Selector>
        {reviewerCompany && value === -1 && !matchingUser.user_selected && (
          <p>
            {reviewerCompany} have automatically been added as a reviewer as the
            automated result is 'Borderline'. One of our specialists will review
            the details and make a determination.
          </p>
        )}
        {matchingUser.user_selected && (
          <ActionButton
            type="danger"
            onClick={(e) => {
              e.preventDefault();
              removeItem(value);
            }}
          >
            Remove
          </ActionButton>
        )}
      </SelectorRow>
    );
  };

  const addUser = (e) => {
    e.preventDefault();
    setLocalUsers([{ user_id: 0 }, ...localUsers]);
  };

  useEffect(() => {
    if (companyId) {
      dispatch(getUsersForCompany(companyId));
    }
  }, [companyId]);

  return (
    <SelectorSurround>
      {loading ? (
        <Loader text="Please wait" />
      ) : (
        <>
          {!users || (!users.length && canViewSettings) ? (
            <p>
              There are no users for this company yet. Add them{" "}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/main/settings/clients/company/${companyId}`);
                }}
              >
                here
              </a>
            </p>
          ) : (
            <p>
              There are no users for this company yet and you don't have
              permission to add any. Please speak to your system administrator.
            </p>
          )}
          {users && users.length ? (
            <>
              <Button
                noFullWidth={true}
                onClick={addUser}
                disabled={hasEmpty || !someNotSelected}
              >
                <StyledFontAwesomeIcon icon={faEye} />
                Add Sign-off user
              </Button>
              {localUsers.map((localUser, idx) =>
                generateSelector(localUser.user_id)
              )}
            </>
          ) : null}
        </>
      )}
    </SelectorSurround>
  );
}

export default withRouter(SignOffSelector);
