import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { orderBy, debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import formatCurrency from "format-currency";
import { StyledDataTable, darkTheme } from "../components/TableStyles";
import {
  getLoading,
  getContractors,
  getTotalRows,
  getCurrentPage,
  getPerPage,
  getSearchTerm,
  getCanShareLeadsForCompany,
} from "../store/contractors/selectors";
import {
  loadContractors,
  removeContractor,
  setPage,
  setPerPage,
  setSearchTerm,
  checkCanShareLeads,
  saveContractor,
} from "../store/contractors/actions";
import {
  getOutOfCredits,
  getHasAdminRole,
  getHasSuperAdminRole,
  getHasClientAdminRole,
} from "../store/users/selectors";
import { loadClients, setCurrentClient } from "../store/clients/actions";
import { getClients, getCurrentClient } from "../store/clients/selectors";
import { TitleArea, ContentArea, Empty, Wrapper } from "../components/Elements";
import noContractors from "../assets/images/no-contractors.svg";
import noResults from "../assets/images/no-search-results.svg";
import Button from "../components/Button";
import Loader from "../components/Loader";
import FormField from "../components/FormField";
import { mainColor } from "../styles/colors";
import { getUserCompany } from "../store/users/selectors";
import Combo from "../components/Combo";

const TableWrapper = styled.div`
  margin: 0px 20px;
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 4px;
  flex: 1;
`;

const Name = styled.div`
  .initials {
    border-radius: 50%;
    background-color: ${mainColor};
    color: #fff;
    text-align: center;
    line-height: 30px;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    display: inline-block;
  }
`;

const StyledCombo = styled(Combo)`
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const generateClientOptions = (clients, userCompany) => {
  if (userCompany) {
    clients = [{ ...userCompany, companies: clients }];
  }

  const generateClientOptionsRecursive = (clients) => {
    return clients.map((client) => ({
      value: client.id,
      name: client.name,
      children: client.companies
        ? generateClientOptionsRecursive(client.companies)
        : [],
    }));
  };

  const finalOptions = generateClientOptionsRecursive(clients);

  return finalOptions;
};

function Contractors({ history, match }) {
  const dispatch = useDispatch();

  const loading = useSelector(getLoading);
  const contractors = useSelector(getContractors);
  const clients = useSelector(getClients);
  const currentClient = useSelector(getCurrentClient);
  const totalRows = useSelector(getTotalRows);
  const perPage = useSelector(getPerPage);
  const page = useSelector(getCurrentPage) + 1;
  const searchTerm = useSelector(getSearchTerm);
  const userCompany = useSelector(getUserCompany);

  const adminRole = useSelector(getHasAdminRole);
  const superAdminRole = useSelector(getHasSuperAdminRole);
  const clientAdminRole = useSelector(getHasClientAdminRole);

  const canChangeComms = adminRole || superAdminRole || clientAdminRole;
  const canShareLeads = useSelector(getCanShareLeadsForCompany);

  useEffect(() => {
    if (currentClient !== 0) {
      dispatch(loadContractors(null, currentClient, false, page, perPage));
      dispatch(checkCanShareLeads(currentClient));
    }
  }, [currentClient]);

  useEffect(() => {
    if (userCompany && !currentClient) {
      dispatch(setCurrentClient(userCompany.id));
    }
  }, [userCompany]);

  useEffect(() => {
    dispatch(loadClients());
  }, []);

  useEffect(() => {
    if (currentClient) {
      if (searchTerm) {
        dispatch(loadContractors(searchTerm, currentClient, false, 1, perPage));
        dispatch(setPage(0));
      }
    }
  }, [searchTerm]);

  const movePage = async (page) => {
    dispatch(loadContractors(null, currentClient, false, page, perPage));
    dispatch(setPage(page - 1));
  };

  const changePerPage = async (newPerPage, page) => {
    dispatch(loadContractors(null, currentClient, false, page, newPerPage));
    dispatch(setPerPage(newPerPage));
    dispatch(setPage(page - 1));
  };

  const [searching, setSearching] = useState(false);

  const NameField = ({ row, onClick }) => (
    <Name onClick={onClick}>
      <span className="initials">
        {row.first_name && row.first_name[0]}
        {row.last_name && row.last_name[0]}
      </span>
      {row.first_name} {row.last_name}
    </Name>
  );

  const renderTable = () => {
    const deleteRow = (row) => {
      const options = {
        title: "Are you sure?",
        message: `Are you sure you want to delete ${row.first_name} ${row.last_name} It's not possible to reverse this operation`,
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              dispatch(removeContractor(row.id));
            },
          },
          {
            label: "No",
          },
        ],
      };
      confirmAlert(options);
    };

    const columns = [
      {
        name: "Name",
        cell: (row) => (
          <NameField
            row={row}
            onClick={() => {
              handleRowClicked(row);
            }}
          />
        ),
      },
      {
        name: "Ltd Company Name",
        selector: "ltd_company_name",
        sortable: true,
      },
      {
        name: "Email",
        selector: "email",
        sortable: true,
      },
      {
        name: "Phone",
        selector: "phone_number",
        sortable: true,
      },
    ];

    if (canShareLeads && canChangeComms) {
      columns.push({
        name: "Send to Kingsbridge",
        width: 120,
        cell: (row) => (
          <FormField
            type="checkbox"
            inputType="checkbox"
            value={row.opt_in_comms}
            changeMonitor={(value) => {
              dispatch(saveContractor({ id: row.id, opt_in_comms: value }));
            }}
          />
        ),
      });
    }

    columns.push({
      cell: (row) => (
        <Button
          action
          type="danger"
          size="reduced"
          onClick={() => deleteRow(row)}
        >
          Delete
        </Button>
      ),
      button: true,
    });

    const handleRowClicked = (row) =>
      history.push(`/main/contractors/edit/${row.id}`);

    return (
      <TableWrapper>
        <StyledDataTable
          noHeader
          progressPending={loading}
          pagination
          paginationServer
          customTheme={darkTheme}
          columns={columns}
          data={contractors}
          fixedHeader
          highlightOnHover
          fixedHeaderScrollHeight="100%"
          title="All Contractors"
          onRowClicked={handleRowClicked}
          paginationDefaultPage={page}
          paginationPerPage={perPage}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={changePerPage}
          onChangePage={movePage}
        />
      </TableWrapper>
    );
  };

  const executeSearch = (value) => {
    if (!value) {
      dispatch(setPage(0));
      dispatch(loadContractors(null, currentClient, false, 1, perPage));
    }
    setSearching(!!value);
    dispatch(setSearchTerm(value));
  };

  let clientOptions = [];

  if (clients) {
    clientOptions = generateClientOptions(clients, userCompany);
  }

  return (
    <Wrapper>
      <TitleArea>
        <div className="title">
          {" "}
          <h1>Contractors</h1>
          {clients && clients.length ? (
            <StyledCombo
              options={clientOptions}
              value={currentClient}
              onChange={(newValue) => {
                dispatch(setCurrentClient(newValue));
                dispatch(setPage(0));
              }}
            />
          ) : null}
          <FormField
            placeholder="Search"
            type="text"
            changeMonitor={(value) => executeSearch(value)}
            className="search"
            value={searchTerm}
          />
        </div>
        <div className="controls">
          <Button
            action
            onClick={() => {
              history.push("/main/contractors/edit/new");
            }}
          >
            New Contractor
          </Button>
        </div>
      </TitleArea>
      <ContentArea>
        {loading && !contractors && !contractors.length ? (
          <Loader />
        ) : contractors && contractors.length ? (
          renderTable()
        ) : (
          <div>
            {!searching ? (
              <Empty>
                <img src={noContractors} />
                <h2>You've not created any contractors yet</h2>
                <p>You can add one by click on 'New Contractor' above</p>
              </Empty>
            ) : (
              <Empty>
                <img src={noResults} />
                <h2>No search results</h2>
                <p>Try searching for something else</p>
              </Empty>
            )}
          </div>
        )}
      </ContentArea>
    </Wrapper>
  );
}

export default Contractors;
