import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getLoading,
  getContractorById,
  getCanShareLeadsForCompany,
} from "../store/contractors/selectors";
import styled from "styled-components";
import {
  createContractor,
  loadContractor,
  saveContractor,
  checkCanShareLeads,
} from "../store/contractors/actions";
import {
  SplitView,
  TitleArea,
  ContentArea,
  Empty,
  Wrapper,
} from "../components/Elements";

import {
  getOutOfCredits,
  getHasAdminRole,
  getHasSuperAdminRole,
  getHasClientAdminRole,
  getHideValidations,
} from "../store/users/selectors";
import { loadClients } from "../store/clients/actions";
import { getClients } from "../store/clients/selectors";
import { getUserCompany } from "../store/users/selectors";
import Loader from "../components/Loader";
import CRUDForm from "../components/CRUDForm";
import Form from "../components/Form";
import FormField from "../components/FormField";
import FormRow from "../components/FormRow";
import noAssessments from "../assets/images/no-assessments.svg";
import Button from "../components/Button";
import EngagementTable from "../components/EnagementTable";
import AssignTags from "../components/AssignTags";

import { getCreditsFewerThan6 } from "../store/users/selectors";
import { getCreditContactDetails } from "../store/users/selectors";

const TableWrapper = styled.div`
  background-color: #eee;
  border-radius: 4px;
  padding: 30px 20px;
  margin: 0px 30px 0px 0px;
  position: relative;

  &.no-pad {
    padding: 0px 20px;
    border-radius: 4px;
  }
`;

const TagsWrapper = styled.div`
  background-color: #eee;
  padding: 30px 20px;
  margin: 0px 30px 30px 0px;
`;

const CreditsWarning = styled.div`
  margin-top: 5px;
  font-size: 12px;
  background-color: #fff;
  padding: 10px;
`;

const ButtonsSurround = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 200px;
`;

function Contractor({ match, history }) {
  const dispatch = useDispatch();

  const loading = useSelector(getLoading);
  const recordId = match.params.contractorId;
  const isNew = recordId === "new";
  const userCompany = useSelector(getUserCompany);
  const outOfCredits = useSelector(getOutOfCredits);
  const hideValidations = useSelector(getHideValidations);
  const adminRole = useSelector(getHasAdminRole);
  const superAdminRole = useSelector(getHasSuperAdminRole);
  const clientAdminRole = useSelector(getHasClientAdminRole);

  const canChangeComms = adminRole || superAdminRole || clientAdminRole;

  const canShareLeads = useSelector(getCanShareLeadsForCompany);

  const contactDetails = useSelector(getCreditContactDetails);
  const lowOnCredits = useSelector(getCreditsFewerThan6);

  const emptyState = {
    first_name: "",
    last_name: "",
    ltd_company_name: "",
    opt_in_comms: true,
  };

  const record = useSelector((state) =>
    getContractorById(state, Number(recordId))
  );

  const clients = useSelector(getClients);

  const [formData, setFormData] = useState(emptyState);

  const [dataFromForm, setDataFromForm] = useState({});

  useEffect(() => {
    dispatch(loadClients());
    if (recordId !== "new") {
      dispatch(loadContractor(recordId));
    }
  }, []);

  useEffect(() => {
    if (record) {
      const recordToUse = record || {};
      setFormData({ ...emptyState, ...recordToUse });
    }
    if (record && record.company_id) {
      dispatch(checkCanShareLeads(record.company_id));
    }
  }, [recordId, record]);

  useEffect(() => {
    if (dataFromForm && dataFromForm.company_id) {
      dispatch(checkCanShareLeads(dataFromForm.company_id));
    }
  }, [dataFromForm]);

  const sendForm = async (formData) => {
    if (isNew) {
      const response = await dispatch(createContractor(formData));
      history.push(`/main/contractors/edit/${response.id}`);
    } else {
      dispatch(saveContractor(formData));
    }
  };

  return (
    <Wrapper>
      {!isNew && !record ? (
        <Loader />
      ) : (
        <Wrapper>
          <TitleArea>
            <div className="title">
              {" "}
              <h1>
                {isNew
                  ? "New Contractor"
                  : `Edit ${formData.first_name} ${formData.last_name}`}
              </h1>
            </div>
            <div className="controls">
              <ButtonsSurround>
                {" "}
                <Button
                  action
                  disabled={isNew || outOfCredits}
                  onClick={() => {
                    history.push(
                      `/main/contractors/assessment/${record.company_id}/${
                        record ? record.id : "new"
                      }/new`
                    );
                  }}
                >
                  New Assessment
                </Button>
                {outOfCredits && (
                  <CreditsWarning>
                    You are out of credits, please speak to your tool provider
                    or email {contactDetails}
                  </CreditsWarning>
                )}
              </ButtonsSurround>
            </div>
          </TitleArea>
          <ContentArea>
            <SplitView>
              <div className="left">
                <CRUDForm
                  emptyState={emptyState}
                  formData={formData}
                  loading={loading}
                  recordId={recordId}
                  onSubmit={sendForm}
                  updater={(newData) => {
                    setDataFromForm(newData);
                  }}
                  validationRules={{
                    email: [
                      {
                        canned: "email",
                      },
                    ],
                  }}
                >
                  <Form internal={true}>
                    <h2>Contractor's Details</h2>
                    <FormRow cols={2}>
                      <FormField
                        name="first_name"
                        label="First Name"
                        type="text"
                      />
                      <FormField
                        name="last_name"
                        label="Last Name"
                        type="text"
                      />
                    </FormRow>
                    <FormRow cols={2}>
                      <FormField
                        name="ltd_company_name"
                        label="Ltd company name"
                        type="text"
                      />

                      <FormField
                        name="ltd_company_number"
                        label="Ltd company number"
                        type="text"
                      />
                    </FormRow>

                    <FormRow cols={2}>
                      <FormField
                        name="email"
                        label="Email Address"
                        type="email"
                      />
                      <FormField
                        name="phone_number"
                        label="Phone Number"
                        type="text"
                      />
                    </FormRow>
                    <FormRow cols={2}>
                      {clients && clients.length ? (
                        <FormField
                          name="company_id"
                          label="Which client does this contractor belong to?"
                          inputType="select"
                          options={[userCompany]
                            .concat(clients)
                            .map((company) => ({
                              value: company.id,
                              name: company.name,
                            }))}
                        />
                      ) : null}
                      <FormField
                        name="external_reference"
                        label="External Reference"
                        type="text"
                        optional={true}
                      />
                    </FormRow>
                    {canChangeComms && canShareLeads && (
                      <FormRow cols={1}>
                        <FormField
                          name="opt_in_comms"
                          label="Share contractor information with Kingsbridge"
                          type="checkbox"
                          inputType="checkbox"
                          optional={true}
                          helpText="If you untick this box, the contractor details will not be shared with Kingsbridge for insurance purposes. If this box is ticked, Kingsbridge will contact your contractor about professional insurances."
                        />
                      </FormRow>
                    )}

                    <FormRow cols={2}>
                      <Button noFullWidth={true}>Save</Button>
                    </FormRow>
                  </Form>
                </CRUDForm>
              </div>
              <div className="right">
                {record && record.id && (
                  <TagsWrapper>
                    <h2>Contractor Tags</h2>
                    <AssignTags
                      companyId={record.company_id}
                      currentCompanyId={dataFromForm.company_id}
                      entityId={record.id}
                      entityType="contractor"
                    />
                  </TagsWrapper>
                )}
                <TableWrapper>
                  {record && record.engagements && record.engagements.length ? (
                    <span>
                      <h2>Assessments</h2>
                      <EngagementTable
                        companyId={record.company_id}
                        data={record.engagements.filter((record) => {
                          if (hideValidations) {
                            return !record.validation;
                          } else {
                            return record;
                          }
                        })}
                        history={history}
                        pagination={true}
                      />
                    </span>
                  ) : (
                    <Empty>
                      <img src={noAssessments} />
                      <h2>
                        You've not created any assessments for this contractor
                        yet
                      </h2>
                      <p>
                        {isNew
                          ? `You'll be able to create assessments once you've saved the new contractor's details`
                          : `You can add one by click on 'New Assessment' above`}
                      </p>
                    </Empty>
                  )}
                </TableWrapper>
              </div>
            </SplitView>
          </ContentArea>
        </Wrapper>
      )}
    </Wrapper>
  );
}

export default Contractor;
