import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import formatCurrency from "format-currency";
import { StyledDataTable, darkTheme } from "../components/TableStyles";
import { getDisplayName } from "./ResultScreen";
import { getHasFeature } from "../store/users/selectors";

const theme = {
  ...darkTheme,
  rows: {
    hoverBackgroundColor: "#ddd",
    fontSize: "16px",
  },
};

function EngagementTable(props) {
  const { history, data, hideStatus, pagination, companyId } = props;

  const hideRate = useSelector((state) => getHasFeature(state, "hide_rate"));

  const columns = [
    {
      name: "Potential Liability",
      cell: (row) =>
        formatCurrency(row.financial_exposure, {
          format: "%s%v",
          symbol: "£",
        }),
    },
    {
      name: "Result",
      cell: (row) =>
        row.assessment
          ? getDisplayName(
              row.assessment.result,
              row.validation ? "SHORT" : "FULL"
            )
          : "Incomplete",
    },
    {
      name: "Assessment Type",
      cell: (row) => {
        if (row.validation) {
          return "Validation";
        } else {
          return "Decision";
        }
      },
    },
  ];

  if (!hideRate) {
    columns.unshift({
      name: "Rate",
      cell: (row) =>
        formatCurrency(row.day_rate, {
          format: "%s%v",
          symbol: "£",
        }),
    });
  }

  const handleRowClicked = (row) =>
    history.push(
      `/main/contractors/assessment/${companyId}/${row.contractor_id}/${row.id}`
    );

  return (
    <StyledDataTable
      noHeader
      pagination={pagination}
      customTheme={theme}
      columns={columns}
      data={data || []}
      fixedHeader
      highlightOnHover
      fixedHeaderScrollHeight="100%"
      onRowClicked={handleRowClicked}
    />
  );
}

export default EngagementTable;
